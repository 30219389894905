@use "sass:math";

@import "ui-components/_variables.scss";
@import "ui-components/_mixins.scss";

$image-height: 320px;

@keyframes shine {
  0% {
    background-position: -100%;
  }
  100% {
    background-position: 100%;
  }
}

.dummy {
  .row {
    background-image: linear-gradient(90deg, color("disabled") 0%, color("background") 50%, color("disabled") 100%);
    background-size: 200%;
    animation: shine 1.5s infinite linear;
    height: size("font--large");
    margin-bottom: size("gutter--mini");
    border-radius: size("border-radius");
  }

  .mainImage {
    background-image: linear-gradient(90deg, color("disabled") 0%, color("background") 50%, color("disabled") 100%);
    background-size: 200%;
    animation: shine 1.5s infinite linear;
    max-height: $image-height;
  }

}

.wrapper {
  margin-bottom: size("gutter--larger");

  video {
    max-width: 100%;
    height: auto;
    aspect-ratio: 16 / 9;
  }

  p {
    max-width: 80ch;
  }
}

.currentInfoItemView {
  width: 80ch;
  max-width: 100%;
  margin: 0 auto;
}

.mainImage {
  width: 100%;
  height: auto;
  object-fit: cover;
  border-radius: var(--borderRadius);
}

.tags {
  display: flex;
  gap: size("gutter--small");
  border-top: solid 2px var(--border);
  padding-top: size("gutter");
  margin-top: size("gutter--huge");
}

.tag {
  display: inline-block;
  background-color: var(--primary);
  font-size: size("font--mini");
  color: var(--invertedText);
  border-radius: calc(var(--borderRadius) - 2px);
  padding: calc(size("grid-gutter") / 2) size("grid-gutter");
}