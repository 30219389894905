@import "ui-components/_variables.scss";
@import "ui-components/_mixins.scss";

.header {
  display: flex;
  margin-bottom: size("gutter--small");

  h1 {
    margin-bottom: 0;
    margin-right: size("gutter--mini");
  }

  @media (max-width: (breakpoint("small") - 1px)) {
    flex-direction: column;
  }

  @media (max-width: (breakpoint("tiny") - 1px)) {
    margin-bottom: size("gutter--mini");
  }
}

.filterbar {
  margin-bottom: size("gutter--small");
}

.activeFilters {
  @media (max-width: (breakpoint("tiny") - 1px)) {
    width: 100%;
  }
}

.total_count {
  margin: 0;
  margin-top: size("grid-gutter");
  margin-bottom: size("gutter--large");
  color: var(--textMuted);
  font-size: size("font");
  line-height: 1.25;

  @media (min-width: (breakpoint("small") - 1px)) {
    align-self: flex-end;
  }
}

.activeFiltersRow {
  display: flex;
  align-items: center;
  
  &Header {
    margin-bottom: 0;
    margin-right: size("gutter");

    @media (max-width: (breakpoint("small") - 1px)) {
      margin-bottom: size("gutter--small");
    }
  }

  &Count {
    color: var(--primary);
  }

  @media (max-width: 900px) {
    flex-direction: column;
    align-items: flex-start;
  }

  @media (max-width: (breakpoint("small") - 1px)) {
    margin-bottom: size("gutter--small");
  }
}
