@use "sass:math";

@import "ui-components/_variables.scss";
@import "ui-components/_mixins.scss";

$points-field-width: 242px;

.header {
  margin-bottom: size("gutter--large") !important;

  h2 {
    font-size: size("font--huge");
  }
}

.subHeader {
  margin: 0 0 size("gutter--small");
}

.wrapper {
  display: flex;
  flex-wrap: wrap;
  width: 85%;
  column-gap: size("gutter--large");
  row-gap: size("gutter--large");
  justify-content: flex-start;
  margin: 0 0 size("gutter--large");

  @media (max-width: breakpoint("medium")) {
    width: 100%;
  }
}

.formblock {
  padding: size("gutter--medium-large");
  background: color("body--off");
  border-radius: var(--borderRadius);
  flex: 1 0 50%;

  @media (max-width: breakpoint("small")) {
    flex: 1 1 100%;
  }

  @media (min-width: breakpoint("small")) {
    width: 617px;
  }
}

.checklist {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: flex-start;
  margin: 0 0 size("gutter--large");

  @media (max-width: breakpoint("tiny")) {
    flex-direction: column;
  }

  &Item {
    flex: 1 1 50%;
    padding: size("grid-gutter") 0;
  }

  &ItemAll {
    flex: 1 0 100%;
    padding: size("grid-gutter") 0;
  }
}

.pointlist {
  display: flex;
  margin: 0 0 size("gutter--large");
  padding: 0 size("gutter--medium-large");
  background: color("body--off");
  border-radius: var(--borderRadius);
  flex-direction: column;

  >div:not(:last-child) {
    border-bottom: 1px solid var(--background);
  }

  &__item {
    padding: size("gutter--small") 0;
    display: flex;
    flex-direction: row;
    column-gap: size("gutter--large");
    justify-content: space-between;

    &Error {
      color: color("negative");
    }
  }
}

.input {

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }

  &[type=number] {
    -moz-appearance: textfield;
  }

  &Wrapper {
    width: 50%;
  }
}

.checkboxName {
  line-height: 1.2;
}

.sidebar {
  flex: 1 0 20%;

  @media (max-width: breakpoint("small")) {
    flex: 1 1 100%;
  }

  &Wrapper {
    position: sticky;
    top: size("header-height") + 20px;

    @media (max-width: breakpoint("small")) {
      position: relative;
      top: 0;
    }
  }

  &Header {
    margin: 0 0 size("gutter--small");
  }
}

.pointpot {
  display: flex;
  flex-wrap: wrap;
  column-gap: size("gutter-large");
  justify-content: space-between;
  flex-direction: column;
  margin: 0;

  >div {
    border-top: 1px solid var(--border);
  }
}

.history {
  margin: 0 0 size("gutter--large");

  &Wrapper {
    margin-top: size("gutter--large");
    padding: 0;
  }

  &Header {
    margin: 0 !important;
  }

  &Table {
    width: 100%;
  }

  h3 {
    margin: 0 0 size("gutter--medium-large");
  }

  tr:not(:last-of-type) {
    border-bottom: 1px solid var(--border);
  }

  th {
    font-size: size("font--small");
    text-align: left;
    font-weight: 400;
    border-bottom: 1px solid var(--border);
    padding: size("grid-gutter") size("gutter--small") size("grid-gutter") 0;
  }

  td {
    vertical-align: top;
    padding: size("gutter--small") 0;
    line-height: font("line-height--paragraph");

    &:not(:last-child) {
      padding-right: size("gutter--small");
    }
  }
}

.noWrap {
  white-space: nowrap;
}

.selector {
  margin-bottom: size("gutter");
  width: 100%;
}

button.Dropdown_head {
  border-radius: 100px !important;
}