@import "ui-components/_variables.scss";
@import "ui-components/_mixins.scss";

.reloadConfigView {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: size("gutter--large") * 2;
  padding-bottom: size("gutter--large") * 2;
  height: 100vh;
  width: 100vw;
  background-color: var(--reloadConfigBackgroundColor, --background);

  h1 {
    font-size: size("font--large");
    color: var(--reloadConfigTextColor, --text);
  }

  .spinner {
    --dot: var(--reloadConfigTextColor, color("border"));
  }
}
