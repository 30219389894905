@use "sass:math";

@import "ui-components/_variables.scss";
@import "ui-components/_mixins.scss";

.block {
  background: var(--light);
  color: var(--primary);
  border: 1px solid var(--border);
  padding: math.div(size("gutter--small"), 1.5) size("gutter--small");
  justify-content: space-between;
  white-space: nowrap;
  align-items: center;
  border-radius: 2rem;
  display: inline-flex;

  &:hover {
    background-color: var(--light-d1);

    .close {
      background: color("negative");
    }
  }

  &:active:hover {
    color: color("text");
  }
}

.active {
  background: var(--primary);
  color: var(--invertedText);

  &:hover {
    background-color: var(--primary-l1);
  }

  &:active:hover {
    color: color("text");
  }
}

.close {
  background: color("text");
  padding: 3px;
  width: 16px;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  color: inherit;
  display: flex;
  right: -#{math.div(size("gutter--small"), 2.5)};
  margin-left: math.div(size("gutter--small"), 3);
  text-decoration: none;
  user-select: none;

  svg {
    color: #fff;
    padding: 1px;
  }
}
