@use "sass:math";

@import "ui-components/_variables.scss";
@import "ui-components/_mixins.scss";

$button-size: 32px;

.pagination {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-bottom: size("gutter") * 2;

  &__numbers {
    display: flex;

    button {
      border-radius: 50%;
      border: 2px solid transparent;

      &.active {
        border-color: var(--primary);
      }
    }
  }

  &__step {
    & + & {
      margin-left: math.div(size("gutter--mini"), 2);
    }
  }

  button {
    width: $button-size;
    height: $button-size;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .prev, .next {
    color: var(--border);
  }

  .prev:not(:disabled), .next:not(:disabled) {
    color: var(--primary);
  }
}

.errorIcon {
  color: var(--negative);
}

.successIcon {
  color: var(--positive);
}

.prev {
  svg {
    transform: rotate(-180deg);
  }
}

.title {
  text-align: center;
}

.subTitle {
  &Closed {
    color: var(--negative);
  }
}

.description {
  margin-bottom: size("gutter--large");
}

.questions {
  margin-top: size("gutter--large");

  .listoption {
    display: block;
    width: 100%;
    text-align: left;
    border: 1px solid var(--borderMuted);
    padding: size("gutter--small") size("gutter") !important;

    &:not(:first-child) {
      margin-top: math.div(size("gutter--mini"), 2);
    }

    &__active {
      background: var(--primary);
      border: 1px solid var(--primary);
      color: var(--light);

      &:hover {
        background: var(--primary-l1);
        border: 1px solid var(--primary-l1);
      }
    }
  }

  .next {
    display: flex;
    justify-content: flex-end;

    &Button {
      margin-top: size("gutter--large") !important;

      @media (max-width: (breakpoint("mini") - 1px)) {
        width: 100%;
      }
    }
  }
}

.radio, .checkbox {
  display: flex;
  align-items: center;
  width: 100%;
  padding: size("gutter--mini") 0;

  &:hover label {
    text-decoration: none !important;
  }

  & + & {
    margin-top: math.div(size("gutter--mini"), 2);
  }
}

.success {
  @media (max-width: (breakpoint("small") - 1px)) {
    &__right {
      margin-bottom: size("gutter--large");
    }
  }
  @media (min-width: breakpoint("small")) {
    @include stretch(size("gutter--small"));

    &__left {
      float: left;
      width: 50%;
      padding: 0 size("gutter--small");
    }

    &__right {
      float: right;
      width: 50%;
      padding: 0 size("gutter--small");
      img {
        width: 100%;
      }
    }
  }

  .backButton {
    margin-top: size("gutter");
  }
}
