@import "ui-components/_variables.scss";
@import "ui-components/_mixins.scss";

@keyframes shine {
  0% {
    background-position: -100%;
  }
  100% {
    background-position: 100%;
  }
}

.block {

}

.connect {
  margin-bottom: size("gutter--large") * 2;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: size("gutter--large");

  h2 {
    margin-bottom: 0;
  }
}

.link {
  display: flex;
  align-items: center;
}

.providers {
  margin-bottom: size("gutter--large") * 2;
}

.nextPage {
  margin-top: size("gutter--large") !important;
}

.lightbox {
  padding: 0 !important;
}

.iframe {
  height: 650px;
  max-height: 100vh;
  width: 400px;
  border: 0;

  @media (max-width: 399px) {
    width: 100%;
  }
}
