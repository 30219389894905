@import "ui-components/_variables.scss";
@import "ui-components/_mixins.scss";

$maxWidth: 550px;
$boxShadow: 0 24px 50px 0 rgba(0, 0, 0, 0.16);
$borderRadius: 6px;
$transitionDuration: transition("duration--organisms");

@keyframes enter {
  0%   {
    opacity: 0;
    transform: translateX(100%);
  }

  50% {
    opacity: 0;
  }

  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes leave {
  0%   {
    opacity: 1;
    height: var(--message-height);
    padding-bottom: #{size("gutter--mini")};
  }

  50% {
    opacity: 0;
  }

  100% {
    opacity: 0;
    height: 0px;
    padding-bottom: 0px;
  }
}

.message {
  padding-bottom: size("gutter--mini");
  pointer-events: auto;
  max-width: $maxWidth;
  min-height: 0;

  @media (max-width: (breakpoint("tiny") - 1px)) {
    max-width: none;
    width: 100%;
  }

  &.deleting {
    animation: leave $transitionDuration transition("ease-out") forwards;
    opacity: 0;
  }
}

.itemInner {
  animation: enter $transitionDuration transition("ease-in") forwards;
  box-shadow: $boxShadow;
  border-radius: $borderRadius;
  background: var(--light);
  padding: size("gutter--medium");
  color: var(--textMuted);

  @media (max-width: (breakpoint("tiny") - 1px)) {
    border-radius: 0px;
  }

  h2 {
    margin: 0;
    margin-right: size("gutter--large");
    margin-bottom: size("gutter");
  }

  p {
    margin-top: 0;
  }

  .buttons {
    display: flex;
    justify-content: flex-end;

    @media (max-width: (breakpoint("mini") - 1px)) {
      justify-content: space-between;
    }

    .button {
      color: var(--primary);
      background: transparent;
      border: none;

      &:not(:first-child) {
        @media (min-width: breakpoint("mini")) {
          margin-left: size("gutter--huge");
        }
      }

      &:hover {
        text-decoration: underline;
      }
    }
  }
}

.icon {
  position: absolute;
  cursor: pointer;
  color: var(--text) !important;
  width: 20px;
  height: 20px;
  top: 0px;
  right: 0px;
  z-index: z-index(overlay)
}

.title {
  font-size: size("font--large");
  font-weight: bold;
}