@import "ui-components/_variables.scss";
@import "ui-components/_mixins.scss";

.block {
  transition: opacity transition("duration--atoms") transition("ease-in");

  &.processing {
    opacity: 0.6;
    pointer-events: none;
  }
}

.closeIcon {
  transition: transform transition("duration--organisms") transition("ease-in");

  transform: rotate(90deg) scaleX(1);
  .open & {
    transform: rotate(90deg) scaleX(-1);
  }
}

.body {
  padding-top: 0;

  table {
    width: 100%;
    line-height: font("line-height");
  }

  td {
    vertical-align: top;

    &:last-child {
      text-align: right;
    }
  }

  hr {
    border-color: color("disabled");
    margin: size("gutter") 0;
  }

  button {
    margin: size("gutter--large") 0;
    margin-bottom: 0;
    width: 100%;
  }

  @media (max-width: (breakpoint("medium") - 1px)) {
  }
}

.tax {
  display: block;
  font-size: size("font--small");
  color: var(--textMuted);
}

.tableSection {
  padding-top: size("gutter--small");
}

.footer {
  padding-top: size("gutter--small");
}

.heading {
  margin-bottom: size("gutter--small") !important;
  padding-top: size("gutter--small");
}

.separatorFull {
  height: 1px;
  background-color: color("disabled");
  margin-left: -#{size("gutter")};
  margin-right: -#{size("gutter")};
  margin-top: size("gutter--small");
}

.separator {
  background-color: color("disabled");
  height: 1px;
  width: 100%;
  margin: size("gutter") 0;
}

.totalRow {
  padding-top: size("gutter--small");
}

.continueShopping {
  display: block;
  text-align: center;
  margin: 0 0 size("gutter--small") !important;
}
