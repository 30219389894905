@import "ui-components/_variables.scss";
@import "ui-components/_mixins.scss";

:export {
  medium: strip-unit(breakpoint("medium"));
}

.prev, .next {
  color: var(--primary);
}

.container {
  display: flex;
  flex-direction: row !important;
}

.containerServer {
  display: flex;
  flex-direction: row !important;

  @media (max-width: (breakpoint("small") - 1px)) {
    & > div:nth-child(1n + 2) {
      display: none !important;
    }
  }

  @media (min-width: breakpoint("small")) and (max-width: (breakpoint("medium") - 1px)) {
    & > div:nth-child(1n + 3) {
      display: none !important;
    }
  }
}

.carouselControls {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: size("gutter") 0;
}
