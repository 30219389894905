@import "ui-components/_variables.scss";

$size: 36px;
$icon-size: 12px;
$min-width: 110px;
$max-width: 140px;

$border-color: color("disabled");

.block {
  display: flex;
  min-width: $min-width;
  max-width: $max-width;
  border: 1px solid $border-color;
  border-radius: var(--borderRadius);
  justify-content: space-between;
  padding: 5px;
}

.increment, .decrement, .input {
  padding: size("gutter--small") 0;
  border: none;

  display: flex;
  justify-content: center;
  align-items: center;

  text-align: center;
}

.increment, .decrement {
  padding: 0;
  height: $size;
  width: $size;
  border-radius: var(--borderRadius);
  display: flex;
  justify-content: center;
  align-items: center;
  background: var(--primary);
  color: var(--light);

  svg {
    fill: var(--light);
  }

  &:disabled, &:disabled:hover {
    background: $border-color;
    border-color: $border-color;
  }
  &:hover {
    background: var(--primary-l1);

  }
  &:active {
    opacity: .5;
  }

  @media (max-width: (breakpoint("tiny") - 1px)) {
    height: calc(#{$size} - 6px);
    width: calc(#{$size} - 6px);
  }
}

.input {
  border-radius: 0;
  border-left: 0;
  border-right: 0;

  width: $size;
  height: $size;

  @media (max-width: (breakpoint("tiny") - 1px)) {
    height: calc(#{$size} - 6px);
    width: calc(#{$size} - 6px);
  }

  flex: 0 0 auto;
}
