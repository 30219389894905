@use "sass:math";

@import "ui-components/_variables.scss";
@import "ui-components/_mixins.scss";

$max-wrapper-width: 830px;


.block {
    padding-bottom: size("gutter--large");
    display: flex;
    justify-content: flex-start;

    .wrapper {
        background: color("background");
        padding: size("gutter--large");
        max-width: $max-wrapper-width;
        border-radius: size("border-radius");
    }

    input {
        margin-top: size("gutter--large");
    }

    /* Chrome, Safari, Edge, Opera */
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
    }

    /* Firefox */
    input[type=number] {
    -moz-appearance: textfield;
    }

    .title {
        margin: size("gutter--small") 0;
    }

    .description {
        color: var(--textMuted);
        font-size: size("font");
    }

    .result {
        margin-top: size("gutter--large");
    }

    .dropdown {
        width: 100%;
        main {
            max-height: 320px;
            overflow-y: scroll;
        }
    }
}