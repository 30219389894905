@import "ui-components/_variables.scss";
@import "ui-components/_mixins.scss";

.row {
  &:not(:first-child) { margin-top: size("gutter"); }
  &:not(:last-child)  { margin-bottom: size("gutter"); }
}

.insufficientFunds {
  padding: size("gutter");
  border: 1px solid var(--border);
  border-radius: size("border-radius");
  margin-bottom: size("gutter--medium");
  transition: border-color transition("duration--atoms") transition("ease-in");

  &.warning {
    border-color: var(--border);
  }
}