@import "ui-components/_variables.scss";
@import "ui-components/_mixins.scss";

.muted {
  color: color("text--muted");
}  

.formListItem {
  background-color: var(--light-d1);
  border: 1px solid var(--border);
  border-radius: var(--borderRadius);

  .textRows {
    padding: size("gutter--medium");
  }

  .controls {
    display: flex;
    padding: size("gutter--medium");
    padding-bottom: 0;
    justify-content: end;
    gap: size("gutter");
  }
}


  
.form {
  margin-top: 0;
}