@use "sass:math";
@import "ui-components/_variables.scss";
@import "ui-components/_mixins.scss";

$container-width: 580px;
$container-height: 240px;
$input-file-height: 50px;

.uploadContainer {
  position: fixed;
  z-index: 120;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;

  &__Inner {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    background-color: #fff;
    padding: size("gutter--larger");
    border-radius: math.div(size("gutter--small"), 2);

    @media (min-width: (breakpoint("medium") - 1px)) {
      width: $container-width;
      min-height: $container-height;
    }

    .title {
      align-self: flex-start;
      margin-bottom: 0;
      font-size: size("font--large");
      font-weight: bold;
    }

    .fileInputContainer {
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: $input-file-height;
      margin-bottom: size("gutter--larger");
      padding: size("gutter--min");
      cursor: pointer;
      color: var(--primary);
      font-weight: bold;

      input {
        opacity: 0;
        position: absolute;
        top: 0;
        bottom: 0;
        right: 0;
        left: 0;
        z-index: 2;
      }

      .bgColor {
        background: (var(--primary));
        opacity: .2;
        position: absolute;
        top: 0;
        bottom: 0;
        right: 0;
        left: 0;
        z-index: 1;
        border-radius: math.div(size("gutter--small"), 2);
      }
    }

    .disabledInput {
      color: color("text--muted");
      cursor: not-allowed;

      input {
        pointer-events: none;
      }

      .bgColor {
        background: color("text--muted");
      }
    }

    button {
      align-self: flex-end;
      padding: size("gutter--small") !important;

    }
  }
}

.form {
  margin-top: size("gutter--large");
  width: 100%;
  align-items: flex-end;

}

.hidden {
  display: none;
}

.shown {
  display: block;
}

.boxBackground {
  background-color: #2229;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
}