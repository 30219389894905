@use "sass:math";

@import "ui-components/_variables.scss";
@import "ui-components/_mixins.scss";

$blockPaddingBottom: 1.8rem;
$inputHeight: 3.8rem;

.registerView {
  display: flex;
  justify-content: center;
  padding: size("gutter--larger") size("gutter") size("gutter--huge");
}

.registerFormWrapper {
  max-width: 90vw;
  width: 900px;
  background-color: var(--background);
  border-radius: var(--borderRadius);
}

.formHeader {
  display: flex;
  justify-content: space-between;
  margin-bottom: size("gutter");
  font-weight: 700;
}

.registerFormOuter {
  background-color: color("body");
  color: color("text");
  padding: size("gutter");
  border-radius: var(--borderRadius);

  .registerForm {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: size("gutter");

    @media (max-width: (breakpoint("tiny"))) {
      gap: size("grid-gutter");
      
      & > div {
        grid-column: 1 / 3;
      }
    }
  }
}

.submitBtn {
  margin-top: size("gutter");
};

.btnContainer {
  display: flex;
  justify-content: flex-end;
  align-items: end;
  grid-column: 1 / 3;
}

.checkboxField {
  grid-column: 1 / 3;
  line-height: 1.2;
  font-size: size("font--small");

  a {
    color: #007bff;
  }
}

.dropdownField {
  .dropdownText > p {
    margin-bottom: math.div(size("gutter--small"), 1.5);
  }

  .dropdown {
    margin-bottom: size("gutter--larger");
    border-radius: var(--bordeRadius);
    width: 100%;
    height: $inputHeight;

    & > button,
    & > input,
    & > div {
      height: $inputHeight !important;
    }

    &__maxHeight {
      main {
        max-height: 300px;
        overflow-y: scroll;
      }
    }
  }

  .error {
    color: var(--negative);
    font-size: size("font--small");
    position: absolute;
    left: 0;
    top: calc(100% - #{$blockPaddingBottom - 0.45rem});
  }
}

.formLogoWrapper {
  display: flex;
  justify-content: center;
  margin: size("gutter--small") 0 size("gutter--large");

  img {
    max-width: 340px;
    max-height: 80px;
  }
}

.passWrapper {
  grid-column: 1 / 3;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: size("gutter");
}

.labelParagraph {
  overflow-y: hidden;
  text-overflow: ellipsis;
}