@import "ui-components/_variables.scss";
@import "ui-components/_mixins.scss";

.loginWrapper {
  background-color: #fff;
  color: #000;
  margin-top: size("gutter");
  margin-bottom: size("gutter");
}

.header {
  margin-bottom: size("gutter--huge") !important;

  h1 {
    font-size: size("font--huge");
  }

  .link {
    svg {
      transform: rotate(180deg);
      color: #000;
    }
  }
}

.link {
  svg {
    transform: rotate(180deg);
  }
}

.content {
  margin-bottom: size("paragraph-margin");
}

@media print {
  .noPrint {
    display: none;
  }
}
