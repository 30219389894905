@import "ui-components/_variables.scss";
@import "ui-components/_mixins.scss";

$animationTimingFunction: transition("ease-ease");
$animationDuration: transition("duration--templates");
$headerHeight: 68px;
$activeFiltersMinHeight: 70px;
$submitButtonHeight: 72px;
$closeButtonSideLength: 36px;
$closeButtonOffsetRight: -8px;


.block {
  border-top: 1px solid var(--borderMuted);
  width: 500px;
  background-color: var(--invertedText);
}
