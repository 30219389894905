@import "ui-components/_variables.scss";
@import "ui-components/_mixins.scss";

.cards {
    display: flex;
    justify-content: center;
    align-items: center;
    border-top: 1px solid var(--border);
  
    @include stretch(size("gutter"));
    padding-top: size("gutter");
    margin-top: size("gutter");
  
    .visa path {
      fill: rgb(26, 31, 113);
    }
  
    &Inner {
      display: flex;

      & > div {
        & > svg {
          height: 24px;
          width: auto;
          margin: 0 size("gutter--small");
        }
      }
    }
  }