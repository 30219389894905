@import "ui-components/_variables.scss";
@import "ui-components/_mixins.scss";

$maxWidthForm: 425px;

.block {
  display: flex;
  justify-content: center;
  padding: size("gutter");
}

.form {
  background-color: var(--light);
  color: var(--text);
  width: $maxWidthForm;
  padding: size("gutter");
  border-radius: var(--borderRadius);
  align-self: center;

  .header {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
  }

  .button {
    margin-top: size("gutter--mini");
    width: 100%;
  }
}

.closeIcon {
  color: var(--text);
}
