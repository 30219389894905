@import "ui-components/_variables.scss";
@import "ui-components/_mixins.scss";

.agreement {
  overflow: auto !important;
  width: 100%;
  max-width: 80vh;
  max-height: 80vh;
  padding: size("gutter--large") size("gutter--huge");

  .top {
    display: flex;

    .right {
      flex: 0 0 auto;

      .close {
        position: sticky;
        right: 0;
        top: 0;
      }
    }
  }

  .header {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
  }

  .content {
    margin-bottom: size("gutter--large");
  }

  &Bottom {
    margin-top: size("gutter--large");

    display: flex;
    justify-content: center;

    > * {
      margin: 0 size("gutter");
    }
  }

  .block {
    margin: size("gutter") 0;
  }

  &Heading {
    margin-bottom: size("gutter--small");
  }

  &MemberTag {
    padding: size("gutter");
    border: 1px solid color(--primary);
  }

  &Radio {
    display: block;
    margin: 1rem 0;
    cursor: pointer;

    input {
      border: 0;
      clip: rect(0 0 0 0);
      height: 1px;
      margin: -1px;
      overflow: hidden;
      padding: 0;
      position: absolute;
      width: 1px;
    }

    span {
      display: block;
      position: relative;
      padding-left: 2.5rem;
    }

    span::before {
      content:"";
      position: absolute;
      top: 50%;
      left: 0;
      transform: translateY(-50%);
      display: inline-block;
      width: 22px;
      height: 22px;
      border-radius: 50%;
      border: 1px solid #909090;
      margin-right: 1rem;
    }

    input:checked + span::after {
      content:"";
      position: absolute;
      top: 50%;
      left: 5px;
      transform: translateY(-50%);
      width: 12px;
      height: 12px;
      background: #909090;
      border-radius: 50%;
    }
  }

  &Button {
    white-space: nowrap;
    color: var(--invertedText);
    padding: size("gutter--mini") size("gutter--small");
    margin: 0 size("gutter--mini");
    background-color: var(--primary);

    &[disabled] {
      background: #eee;
      color: rgba(var(--text),.35);
    }
  }

  &RadioTitle {
    margin-bottom: size("gutter--small");
  }

  &Error {
    color: var(--negative);
  }

  @media (max-width: (breakpoint("small") - 1px)) {
    max-width: inherit;
  }

  @media (max-width: (breakpoint("mini") - 1px)) {
    padding: size("gutter--small");
  }
}

@media print {
  .noPrint {
    display: none;
  }
}
