@import "ui-components/_variables.scss";
@import "ui-components/_mixins.scss";

.block {
  margin-top: -#{size("header-margin")} !important;

  background-size: cover;
  background-position: center;

  padding: size("gutter--section") * 1.2 0;

  @media (max-width: (breakpoint("small") - 1px)) {
    //padding-top: size("gutter--section") * 1.5;
    //padding-bottom: size("gutter--large");
    background-image: none !important;
    padding: 0;
  }
}

.titles {
  //@media (min-width: breakpoint("small")) {
    margin-top: size("gutter--mini");
  //}
}

.mobileBG {
  @media (min-width: breakpoint("small")) {
    display: none;
  }
}

.content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  background-color: rgba(255, 255, 255, 0.93);
  color: var(--text);
  padding: size("gutter");
  max-width: 600px;
  border-radius: size("border-radius");

  .subTitle {
    font-size: size("font--medium");
    margin-top: 0;
    margin-bottom: 0;
  }

  .title {
    margin-bottom: size("gutter--medium");
    margin-top: 0;
    font-size: size("font--huge") * 1.125;

    @media (max-width: (breakpoint("small") - 1px)) {
      margin-top: 0 !important;
    }
  }

  .description {
    font-size: size("font--medium-small");
    line-height: font("line-height--medium-small");
  }

  @media (max-width: (breakpoint("small") - 1px)) {
    border-radius: 0;

    .title {
      font-size: size("font--medium");
      margin-top: size("gutter--small");
      margin-bottom: size("gutter--small");
    }

    .description {
      display: none;
    }

    .subTitle {
      font-size: size("font");
    }
  }

  @media (max-width: (breakpoint("small") - 1px)) {
    background-color: var(--primaryAlt);
    color: var(--invertedText);
    position: initial;
    max-width: none;
    padding: size("gutter--large");

    @media (min-width: breakpoint("small")) {
      > * {
        max-width: 60%;
      }
    }
  }
}

.wrapper {
  @media (max-width: (breakpoint("small") - 1px)) {
    padding: 0;
  }
}
