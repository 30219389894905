@import "ui-components/_variables.scss";
@import "ui-components/_mixins.scss";

.block {
  min-height: 18rem;
}

.titlePrefix {
  font-weight: normal;
}

.filterbar {
  margin-bottom: size("gutter--small");
}

.activeFilters {
  @media (max-width: (breakpoint("tiny") - 1px)) {
    width: 100%;
  }
}

.activeFiltersRow {
  display: flex;
  align-items: center;
  
  &Header {
    margin-bottom: 0;
    margin-right: size("gutter");

    @media (max-width: (breakpoint("small") - 1px)) {
      margin-bottom: size("gutter--small");
    }
  }

  &Count {
    color: var(--primary);
  }

  @media (max-width: 900px) {
    flex-direction: column;
    align-items: flex-start;
  }

  @media (max-width: (breakpoint("small") - 1px)) {
    margin-bottom: size("gutter--small");
  }
}
