@import "ui-components/_variables.scss";
@import "ui-components/_mixins.scss";

.block {
  flex: 1 0 auto;
}

.heading {
  display: block;
  font-size: size("font--medium");
  font-weight: bold;
}

.back {
  border: none;
  background: none;
  padding: 0;
}

.close, .submit {
  @include resetInput;
  cursor: pointer;
}

.container {
  display: flex;
  align-items: center;
  background: var(--light);
  border-radius: var(--borderRadius);

  @media (max-width: (breakpoint("small") - 1px)) {
    border-bottom: 1px solid var(--border);
    padding: size("gutter");
  }

  @media (min-width: breakpoint("small")) {
    box-shadow: inset 0 1px 3px 0 rgba(0, 0, 0, 0.5);

    padding-left: size("gutter--mini");
    padding-right: size("gutter--mini");

    height: calc(#{size("sub-header-height")} - #{size("gutter--mini")});
  }

  @media (min-width: breakpoint("large")) {
    padding-top: 0 !important;
  }
}

.icon {
  flex: 0 1 auto;
  margin-right: size("gutter");
  color: var(--text);
}

.input {
  flex: 1 1 auto;

  width: 100%;
  border: none;

  background: none;

  cursor: text;
  text-decoration: none;

  @include placeholderStyles {
    color: var(--textMuted);
  }

  &:focus {
    outline: none;
  }

  @media (max-width: (breakpoint("small") - 1px)) {
    border-radius: 0;
    font-size: size("font--medium-small") !important;
  }
}

.suggestions {
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  background: color("body");
  border: 1px solid var(--border);
  border-radius: var(--borderRadius);
  background: #fff;
}

.suggestion {
  display: block;
  padding: size("gutter--small");
  font-size: size("font--small");
  text-decoration: none !important;
  color: var(--text) !important;

  &Wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &:hover, &:active {
    background: var(--primary-l1) !important;
    color: #fff !important;

    & .suggestionBorder {
      border-top: 1px solid transparent;
    }
  }

  &Name, &Type {
    margin-top: 0;
    margin-bottom: 0;
  }

  &Type {
    font-weight: bold;
  }

  &Border {
    border-top: 1px solid var(--border);
    top: -#{size("gutter--small")};
    margin-top: -1px;
  }

  &:first-child .suggestionBorder {
    border-top: 1px solid transparent;
  }
}
