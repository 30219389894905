@import "ui-components/_variables.scss";
@import "ui-components/_mixins.scss";

$editButtonPadding: 10px;
$submitButtonHeight: 54px;

:export {
  textColor: color("text");
  colorMuted: color("text--muted");
}

.block {
  &.loading {
    opacity: .6;
  }

  h4 {
    font-size: size("font");
    color: color("text");
    margin: 0;
  }
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: size("gutter") 0;
  color: var(--primary);;
}

.button {
  display: block;
  width: 100%;
  height: $submitButtonHeight !important;
  padding: 0 !important;
}

.terms {
  display: flex;
  align-items: center;
  user-select: none;
  font-size: size("font--small");

  & > *:last-child {
    height: 0px !important;
    top: -4px;
    margin-left: 7px;

    & > * {
      padding-top: 0;
    }
  }

  label {
    cursor: pointer;
  }

  padding: size("gutter") 0;

  svg {
    color: var(--primary);
  }

  .link {
    color: var(--primary);
    text-decoration: underline;
  }
}

.editButton {
  font-size: size("font--small") !important;
  margin: -#{$editButtonPadding} !important;
  padding: $editButtonPadding !important;
}

.submitBlock {
  padding-top: size("gutter");
}

.cards {
  display: flex;
  justify-content: center;
  margin-top: size("gutter");
  padding: size("gutter");

  .visa path {
    fill: rgb(26, 31, 113);
  }

  &Inner {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin: -#{size("gutter--mini")} -#{size("gutter--small")};

    div {
      margin: size("gutter--mini") size("gutter--small");
      height: 24px;
    }

    svg {
      height: 100%;
      width: auto !important;
    }
  }
}

.stripe {
  &Error {
    margin: size("gutter--small") 0;
    color: color("negative");
  }

  &Row {
    & + & {
      margin-top: calc(#{size("gutter")} / 2);
    }

    &:last-of-type {
      margin-bottom: size("gutter--large");
    }
  }

  &Split {
    @include stretch();
    @include clearfix();
  }

  &Left, &Right {
    width: 50%;
    float: left;
    padding: 0 calc(#{size("grid-gutter")} / 2);
  }

  &Button {
    display: block;
    width: 100%;
    padding: 0 !important;
    height: $submitButtonHeight !important;
  }
}

.icons {
  display: flex;
  justify-content: center;

  &__icon {
    height: 24px;
    width: 64px;

    &:not(:first-child) {
      margin-left: size("gutter--small");
    }
  }
}

.modal {

  .close_button {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 1;
    height: 36px;
    width: 36px;

    & > svg {
      width: 100%;
      height: 100%;
    }
    
    @media (min-width: breakpoint("tiny")) {
      right: size("gutter");
    }
  }
}

.orParagraph {
  display: block;
  text-align: center;
  padding: size("gutter") 0;
}

.container {
  overflow-y: auto;
  max-height: calc(100vh - #{size("gutter--large") * 2});
  max-width: calc(100vw - #{size("gutter") * 2});

  @media (min-width: breakpoint("tiny")) {
    max-height: calc(100vh - #{size("gutter--section") * 2});
    max-width: calc(100vw - #{size("gutter--section") * 2});
  }
}
