@use "sass:math";

@import "ui-components/_variables.scss";
@import "ui-components/_mixins.scss";

$sortWidth: 220px;
$filterbarHeight: size("filterbar-height");
$dropdownSortWidthLarge: 180px;

.block {
  height: $filterbarHeight;
  display: flex;
  align-items: center;

  &__left, &__right {
    display: flex;
    align-items: center;
    height: 100%;
  }

  &__left {
    flex: 1;

    .item {
      height: 100%;

      &:not(:last-child) {
        padding-right: math.div(size("gutter"), 2);
      }

      &:not(:first-child) {
        padding-left: math.div(size("gutter"), 2);
      }
    }
  }

  &__right {
    .item {
      padding-left: math.div(size("gutter"), 2);
    }
  }

  .sort {
    font-weight: bold;
    width: $dropdownSortWidthLarge;

    & > div  {
      height: 100%;
      width: 100%;

      button {
        height: 100%;

        div {
          font-size: size("font--small");
        }
      }
    }

    @media (max-width: (breakpoint("tiny") - 1px)) {
      flex-basis: 50%;
      width: auto;
      padding-right: 0px !important;
    }
  }
}

.item {
  display: flex;
  align-items: center;
  font-size: size("font--small");
}

.price {
  display: none;
  color: var(--textMuted);

  @media (min-width: breakpoint("medium")) {
    flex: 1;
    display: flex;
  }

  & > span {
    white-space: nowrap;
    align-self: center;

    &:first-child {
      margin-right: size("gutter--large");
    }

    &:last-child {
      margin-left: size("gutter--large");
    }
  }

  .slider {
    flex: 1;
    width: auto !important;
  }
}

.totalCount {
  @media (max-width: (breakpoint("tiny") - 1px)) {
    display: none;
  }
}

.filter {
  @media (max-width: (breakpoint("tiny") - 1px)) {
    flex-basis: 50%;
  }
}

.filterOnPoints {
  color: var(--text);
  font-weight: 700;
}

.filterButton {
  padding: size("gutter--small") !important;
  border: 1px solid var(--border) !important;
  height: 100%;
  font-weight: bold;
  background: var(--light);
  transition: transition("duration--molecules") opacity transition("ease-in");
  width: 100%;

  &:hover:not(.active) {
    opacity: 0.7;
  }

  &.active {
    color: color("primary");
    border-color: color("primary");
    animation: pulse transition("duration--organisms") transition("ease-ease") forwards;
  }

  &.outOfStock {
    color: var(--textMuted);
  }
}
