@import "ui-components/_variables.scss";
@import "ui-components/_mixins.scss";

.title {
  font-size: size("font--huge");
  margin: 0 0 size("title-margin");

  @media (max-width: (breakpoint("tiny") - 1px)) {
    font-size: size("font--large");
    margin: 0 0 size("gutter--medium-large");
  }
}
