@use "sass:math";

@import "ui-components/_variables.scss";
@import "ui-components/_mixins.scss";

.wrapper {
  padding-bottom: size("gutter--larger");
}

.breadcrumbsWrapper {
  padding: 0;
}

.description {
  margin-bottom: size("gutter--huge");
  max-width: 35rem;
}

.items {
  display: grid;
  gap: #{size("gutter--small") * 2};

  @media (min-width: breakpoint("small")) {
    grid-template: repeat(2, 1fr) / repeat(2, 1fr);
  }

  @media (min-width: breakpoint("large")) {
    grid-template: repeat(3, 1fr) / repeat(3, 1fr);
  }
}

.no_news {
  padding-bottom: size("gutter--large");

  @media (min-width: breakpoint("small")) {
    padding: 0 math.div(size("gutter"), 2);
    padding-bottom: size("gutter--large");
  }
}
