@import "ui-components/_variables.scss";
@import "ui-components/_mixins.scss";

.minimized {
  &Name {
    margin-bottom: size("gutter--small") * 0.8;
  }

  &Info {
    color: var(--textMuted);
    font-size: size("font--small");
  }
}

.preview {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.editing {
  @media (min-width: breakpoint("small")) {
    padding-bottom: 0px;
  }
}
