@import "ui-components/_variables.scss";

$ribbonIcon: 180px;

.anniversaryBadgeWrapper {
  position: absolute;
  height: auto;
  z-index: 40;
  bottom: calc(0px - ($ribbonIcon / 5));
  left: 50%;
  transform: translateX(-50%);
  width: calc($ribbonIcon / 1.5);

  @media (min-width: breakpoint("small")) {
    bottom: calc(0px - ($ribbonIcon / 4));
    width: $ribbonIcon;
  }
}
