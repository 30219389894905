@import "ui-components/_variables.scss";
@import "ui-components/_mixins.scss";

$tableMinHeight: 200px;
$selectionColor: #fbfbfb;
$ownerIconSize: 17px;

:export {
  small: strip-unit(breakpoint("small"));
}

.tableSmall,
.tableLarge {
  display: flex;
  margin-top: size("gutter--small");
  flex-direction: column;
  width: 100%;
  min-height: $tableMinHeight;

  span {
    line-height: font("line-height");
  }

  tbody,
  .tbody {
    border-top: 2px solid var(--border);
    border-bottom: 2px solid var(--border);
    // border-radius: var(--borderRadius);
  }

  thead th,
  .th {
    padding: size("gutter--small");
    font-weight: normal;
    font-size: size("font--small");
    text-align: left;
  }

  .thead {
    display: flex;
  }

  .tr {
    display: flex;
    cursor: pointer;
    user-select: none;
    transition: opacity transition("duration--atoms") transition("ease-in");

    &:hover {
      opacity: 0.6;
      text-decoration: underline;
    }
  }

  .chevron {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    flex: 1 0 10%;
    padding: 0 size("gutter--small") 0 0 !important;

    svg {
      transition: transform transition("duration--atoms") transition("ease-in");
    }
  }

  .shrink {
    min-width: none;
    overflow-x: hidden;
    text-overflow: ellipsis;
    
    span {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  .tr.open .chevron svg {
    transform: rotate(90deg);
  }

  .tr.open {
    background-color: $selectionColor;
  }

  tr td,
  .tr > div {
    padding: size("gutter--small");
  }

  tr td,
  .tr:not(:first-child) > div {
    border-top: 1px solid var(--border);
  }

  .negative {
    color: var(--negative);
  }

  .spacer {
    flex: 1;
  }

  .tableBtnWrapper {
    display: flex;
    gap: size("grid-gutter");
  }  
}

.tableLarge {
  @media (max-width: (breakpoint("small") - 1px)) {
    display: none;
  }

  .tr {
    cursor: auto;
    
    &:hover {
      opacity: 1;
      text-decoration: none;
    }
  }

  .th_icon,
  .no_icon {
    padding: size("gutter--small");

    &::before {
      content: "";
      display: block;
      width: $ownerIconSize;
      height: $ownerIconSize;
    }
  }

  .tr_icon,
  .no_icon,
  .th_icon {
    padding-right: 0 !important;

    svg {
      min-width: $ownerIconSize;
    }
  }

  .tr > *,
  .thead > * {
    flex: 1 0 21%;
    display: flex;
    align-items: center;

    &:nth-child(1) {
      flex-basis: 34px;
      flex-grow: 0;
      flex-shrink: 0;
    }

    &:nth-child(2) {
      flex-basis: 25%;
    }

    &:nth-child(4) {
      flex-basis: 10%;
    }

    &:nth-child(5) {
      flex-basis: 15%;
    }

    &:last-child {
      flex-basis: 16%;
      flex-shrink: 1;
      justify-content: flex-end;
      align-items: center;
    }
  }
}

.tableSmall {
  @media (min-width: breakpoint("small")) {
    display: none;
  }

  .tableBtnWrapper {
    justify-content: flex-end;
  }

  .tr > *,
  .thead > * {
    flex: 1 0 auto;

    &:nth-child(1) {
      flex-basis: clamp(160px, 40%, 40%);
      flex-grow: 0;
      flex-shrink: 0;
    }

    &:nth-child(2) {
      flex-basis: 40%;
    }

    &:nth-child(3) {
      flex-basis: 40px;
      flex-shrink: 1;
    }
  }
}

.tableHintSmall,
.tableHintLarge {
  margin-top: size("gutter--large");
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: $tableMinHeight;
}

.tableHintLarge {
  @media (max-width: (breakpoint("small") - 1px)) {
    display: none;
  }
}

.tableHintSmall {
  @media (min-width: breakpoint("small")) {
    display: none;
  }
}

.formWrapper {
  padding: size("gutter--small") size("gutter--small") 0;

  .form {
    display: grid;
    width: 100%;
    grid-template-columns: repeat(3, 1fr);
    align-items: center;
    column-gap: size("gutter--small");

    .checkboxWrapper {
      display: flex;
      gap: size("gutter--small");

      @media (max-width: breakpoint("small")) {
        flex-direction: column;
        align-self: flex-start;
        margin-top: 0.1rem;
      }
    }

    .field {
      margin-bottom: size("gutter--large");
      padding-bottom: 0;
    }
  }

  @media (max-width: breakpoint("tiny")) {
    overflow: auto;

    .form:not(#foo) {
      grid-template-columns: repeat(1, 2fr);
    }

    .checkbox:last-child {
      padding-bottom: size("gutter");
    }
  }

  @media (min-width: breakpoint("medium")) {
    width: 80%;
  }
}

.chevron {
  width: 50px;
  @media (min-width: breakpoint("small")) {
    display: none;
  }
}

.outOfFocus {
  opacity: 0.5;
  pointer-events: none;
}

.description p {
  margin: size("gutter") 0;
}

.expandable {
  border-top: 1px solid var(--border);
  background-color: $selectionColor;
  display: flex;
  flex-direction: column;

  table {
    width: 100%;
    margin: 0;
  }

  tbody {
    border: none;
  }

  td {
    border: none !important;
    width: 50%;
  }
}

/* SMALL DETAIL ADJUSTMENTS */

.addBtn {
  margin: size("gutter--medium") 0 0;
  color: var(--primary);

  &:hover,
  &:focus {
    text-decoration: underline;
  }
}

.tableBtn {
  height: 24px;
  width: 24px;

  @media (max-width: breakpoint("small")) {
    height: 32px;
    width: 32px;
  }
}

.disabledBtn {
  opacity: 0.5;

  svg {
    color: var(--textMuted);
    stroke: var(--textMuted);
  }
}

.ownerIcon {
  margin-left: size("grid-gutter");
}

.deleteIcon {
  display: flex;
  height: 18px;
  width: 18px;
  color: var(--negative);

  g,
  path {
    transform: scale(1.10);
  }

  @media (max-width: breakpoint("small")) {

    height: 22px;
    width: 20px;

    g,
    path {
      transform: scale(1.20);
    }
  }
}

.addIconWrapper {
  background-color: var(--primary);
  display: inline-flex;
  align-items: center;
  border-radius: 50%;
  padding: 1px;

  .addIcon {
    height: 18px;
    width: 18px;
    color: #fff;

    path {
      transform: scale(0.75);
    }
  }
}

.spinnerWrapper {
  padding: size("gutter--large") 0;

  & > div {
    justify-content: center;
  }
}

.noMembersField {
  display: flex;
  justify-content: center;
  padding: size("gutter--medium");
}

.subTable {
  margin-top: 0;
  min-height: 0;
  border: none;

  .tbody {
    border: none;
    border-radius: 0;
  }

  .tr {
    &:not(:first-child) > div {
      border-top: none;
    }

    &:last-child > div {
      border-top: 1px solid var(--border);
      padding-bottom: 0;
    }

    &:hover {
      opacity: 1;
      text-decoration: none;
      cursor: auto;
    }
  }
}