@import "ui-components/_variables.scss";
@import "ui-components/_mixins.scss";

$headerHeight: 60px;

.block {
  border-bottom: 1px solid var(--borderMuted);

  &__header {
    height: $headerHeight;
    padding: 0 size("gutter--large");
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid transparent;
    transition: border-bottom-color transition("duration--organisms") transition("ease-ease");

    .open & {
      border-bottom-color: var(--borderMuted);
    }

    & > h3 {
      margin: 0;
      padding: 0;

      & > span {
        color: var(--primary);
      }
    }

    .closeIcon {
      transform: rotate(90deg);
      transition: transform transition("duration--organisms") transition("ease-ease");

      .open & {
        transform: scaleY(-1) rotate(90deg);
      }
    }
  }

  &body {
    opacity: 0;
    transition: opacity transition("duration--organisms") transition("ease-ease");

    .open & {
      opacity: 1;
    }
  }
}
