@import "ui-components/_variables.scss";
@import "ui-components/_mixins.scss";

$tableMinHeight: 200px;

@keyframes shine {
  0% {
    background-position: -100%;
  }
  100% {
    background-position: 100%;
  }
}

.tableLarge {
  width: 100%;
  margin-top: size("gutter--large");
  min-height: $tableMinHeight;
  display: flex;
  flex-direction: column;

  @media (max-width: (breakpoint("small") - 1px)) {
    display: none;
  }

  tbody, .tbody {
    border-bottom: 1px solid var(--border);
    border-radius: var(--borderRadius);
  }

  thead th, .th {
    padding: size("gutter--small");
    padding-left: 0;
    font-weight: normal;
    font-size: size("font--small");
    text-align: left;
  }

  .thead {
    display: flex;
  }

  .tr {
    display: flex;
    cursor: pointer;
    user-select: none;
    transition: opacity transition("duration--atoms") transition("ease-in");

    &:hover {
      opacity: .6;
      text-decoration: underline;
    }
  }

  .tr > *, .thead > * {
    flex: 1 0 45%;
  }

  .chevron {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    flex: 1 0 10%;
    padding: 0 size("gutter--small") 0 0 !important;

    svg {
      transition: transform transition("duration--atoms") transition("ease-in");
    }
  }

  .tr.open .chevron svg {
    transform: rotate(90deg);
  }


  tr td, .tr > div {
    padding: size("gutter--small");
    padding-left: 0;
  }

  tr td, .tr:not(:first-child) > div {
    border-top: 1px solid var(--border);
  }

  .negative {
    color: var(--negative);
  }

  .receiptLink {
    display: flex;
    align-items: center;
    color: var(--primary);

    &:hover, &:focus {
      color: var(--primary-l1);

      span {
        text-decoration: underline;
      }
    }

    svg {
      width: 14px;
      height: 14px;
      margin-right: size("gutter--mini");
      top: 1px;
    }
  }

  .spacer {
    flex: 1;
  }

  &Dummy {
    thead th, .th,
    tbody td {
      span {
        display: inline-block;
        background-image: linear-gradient(120deg, color("disabled") 0%, color("background") 50%, color("disabled") 100%);
        background-size: 200%;
        animation: shine 1.5s infinite linear;
      }
    }

    thead th, .th {
      span {
        height: size("font");
        width: 40%;
      }
    }

    tbody td span {
      height: size("font--medium-small");
      width: 65%;
    }
  }
}

.firstDimmed {
  background-image: linear-gradient(to right, #000 15%, rgba(255, 255, 255, 0) 0%);
  background-position: top;
  background-size: 10px 2px;
  background-repeat: repeat-x;

  * {
    border-top: none !important;
  }
}

.dimmedRow {
  color: #aaa;
}

.selectedCustomer {
  color: #25a;
  svg {
    fill: #25a;
    margin: (size("gutter--small") * -1) 0;
  }
}