@import "ui-components/_variables.scss";
@import "ui-components/_mixins.scss";

.serviceLink {
    &Block {
      background: var(--border);
      padding: calc(#{size("gutter--medium")} * 1.6) size("gutter--medium");
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    &Icon {
      margin-bottom: size("grid-gutter");
    }
  
    &Text {
      flex: 1 0 auto;
      text-align: center;

      span, a {
        padding: size("grid-gutter") 0;
      }

      span {
        font-weight: bold;
      }
  
      a {
        display: inline-block;

        font-size: size("font--medium-small");
        margin-top: size("gutter--mini");

        &:hover {
          text-decoration: underline;
        }
      }
    }
  }
  
  