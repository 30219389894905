@use "sass:math";

@import "ui-components/_variables.scss";
@import "ui-components/_mixins.scss";

$infoMarginTop: 0.8rem;
$bottomHeight: 94px;


@keyframes shine {
  0% {
    background-position: -100%;
  }
  100% {
    background-position: 100%;
  }
}

.block {
  display: flex;
  flex-direction: column;
  background: var(--light);
  border-radius: var(--borderRadius);
  border: 1px solid var(--border);
  color: var(--text);

  transition: transform transition("duration--atoms") transition("ease-in"),
              box-shadow transition("duration--atoms") transition("ease-in");

  &:not(.dummy):hover {
    text-decoration: none;
    box-shadow: 0 8px 15px 0 rgba(0, 0, 0, 0.1);
    transform: translateY(-3px);

    .name {
      text-decoration: underline;
    }
  }
}

.top, .bottom {
  padding: size("gutter");
}

.bottom {
  height: $bottomHeight;
}

.top {
  border-bottom: 1px solid var(--border);

  display: flex;
  align-items: center;
  justify-content: center;
}

.image {
  position: absolute;
  max-height: 100%;
  padding: size("gutter--small");

  @media (max-width: (breakpoint("small") - 1px)) {
    padding: size("gutter--mini");
  }
}

.imagePixel {
  display: block;
  flex: 0 0 auto;
  max-width: 100%;
  width: 100%;
}

.categories, .name, .info {
  margin-top: 0;
  margin-bottom: 0;
}

.categories {
  color: var(--textMuted);
  font-size: size("font--small");
  margin-bottom: math.div(size("gutter--mini"), 2);
  line-height: 1;
}

.name {
  font-weight: bold;
  border-color: var(--text) !important;
  margin-bottom: math.div(size("gutter--small"), 2);
  font-size: size("font");
  line-height: 1;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.info {
  margin-top: $infoMarginTop;
  font-size: size("font--mini");
  line-height: 1;
  overflow: hidden;
  text-overflow: ellipsis;
  height: size("gutter") * 2;
}

.dummy {
  .top {
    position: relative;
    display: block;
    flex: 0 0 auto;
    max-width: 100%;
    width: 100%;
    background-image: linear-gradient(90deg, color("disabled") 0%, color("background") 50%, color("disabled") 100%);
    background-size: 200%;
    animation: shine 1.5s infinite linear;
  }

  .bottom {
    p {
      display: block;
      background-image: linear-gradient(90deg, color("disabled") 0%, color("background") 50%, color("disabled") 100%);
      background-size: 200%;
      animation: shine 1.5s infinite linear;
      height: size("font");

      &:first-child {
        margin-bottom: math.div(size("gutter--small"), 2);
      }
    }
  }
}

