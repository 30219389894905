@import "ui-components/_variables.scss";
@import "ui-components/_mixins.scss";

.wrapper {
	margin-top: size("gutter--large");
  margin-bottom: size("gutter--large");
  border-radius: size("border-radius");
  display: flex;
  flex-direction: column;

	@media (max-width: (breakpoint("medium") - 1px)) {
		width: 100%;
    padding: 0;
    margin-bottom: 0;
	}
}

.right {
  padding-top: 0;
}

.box {
  padding: 0;
  border-radius: size("border-radius");
  overflow: hidden;
  h4 {
    font-weight: bold;
  }
	
  &:not(:last-of-type) {
    margin-bottom: size("gutter--large");
  }
}

.header {
  background-color: var(--primary);
  padding: size("gutter");
}

.criteria{
  background-color: var(--primary-l2);
}

.body {
  background-color: color("disabled");
  padding: size("gutter");
  
  ul{
    list-style: inherit;
    margin-left: size("gutter");
    li{
      padding-bottom: size("grid-gutter");
    }
  }

  div{
    margin-top: size("gutter--medium");
  }
}