@use "sass:math";

@import "ui-components/_variables.scss";
@import "ui-components/_mixins.scss";

$sliderLeftMargin: .8rem;
$sliderTopMargin: .85rem;

.box {
  background: var(--light);
  border-radius: size("border-radius");
  padding: size("gutter");
}

.pointsHeading {
  margin-bottom: 0;
}

.balance {
  color: var(--textMuted);
  margin-top: math.div(size("gutter--mini"), 3);
  margin-bottom: size("gutter--large");
}

.pointSelect {
  @media (min-width: breakpoint("small")) {
    display: flex;

    &>div:last-child {
      margin-left: size("gutter--large");
    }
  }

  @media (max-width: (breakpoint("small") - 1px)) {
    &>div:last-child {
      margin-top: size("gutter--large");
    }
  }
}

.slider {
  flex: 1 0 auto;
  padding-top: $sliderTopMargin;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  & > div:first-child {
    margin-left: $sliderLeftMargin;
    width: calc(100% - #{$sliderLeftMargin}) !important;
  }
}

.sliderLabels {
  display: flex;
  align-items: center;
  justify-content: space-between;

  color: var(--textMuted);
}

.summary {
  display: flex;
  justify-content: space-between;

  padding-top: size("gutter--large") * 1.5;
  margin-top: size("gutter--large") * 1.5;
  border-top: 1px solid color("disabled");
  transition: opacity transition("duration--organisms") transition("ease-ease");

  &.loading {
    opacity: .4;
  }

  h2 {
    margin: 0;
  }

  & > div:last-child {
    text-align: right;
  }

  &__label {

  }

  &IncVat {
    font-size: size("font--medium");

    & > * {
      display: block;

      &:last-child {
        margin-top: math.div(size("gutter--mini"), 2);
      }
    }
  }

  &ExVat {
    margin-top: size("gutter--medium");
    font-size: size("font");
    color: var(--textMuted);

    & > * {
      display: block;

      &:first-child {
        font-weight: bold;
      }

      &:not(:first-child) {
        margin-top: math.div(size("gutter--mini"), 2);
      }
    }
  }
}

.tax {
  display: block;
  font-size: size("font--small");
  color: var(--textMuted);
}
