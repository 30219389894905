@use "sass:math";
@import "ui-components/_variables.scss";

.btnField {
  display: flex;
  gap: size("grid-gutter");
  margin-bottom: size("gutter");
}

.active:not(#foo) {
  text-decoration: underline;
  color: var(--primary);
}

.customBtn:not(#foo) {
  height: 100%;
  background-color: var(--border);
  border: none;
  font-size: size("font");

  @media (max-width: breakpoint("tiny")) {
    font-size: size("font--small");
    padding: size("gutter--mini") size("gutter--small");

    word-wrap: break-word;
    hyphens: auto;
  }
}

.customBtn:not(.active) {
  color: var(--textMuted);
}

.description p {
  margin: size("gutter") 0;
}