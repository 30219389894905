@use "sass:math";

@import "ui-components/_variables.scss";
@import "ui-components/_mixins.scss";

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.tagsFilter {
  display: flex;
  flex-wrap: wrap;
  gap: size("gutter--mini");
  animation: fadeIn 0.5s;
  margin-bottom: size("gutter--small");
}

.tag {
  display: inline-block;
  background-color: var(--primary);
  font-size: size("font--small");
  color: var(--invertedText);
  border: solid 1px var(--primary);
  border-radius: calc(var(--borderRadius) - 2px);
  padding: calc(size("gutter--mini") / 2) size("gutter--mini");

  &:focus,
  &:hover {
    background-color: color-mix(in srgb, var(--primary) 80%, black 20%);
    transition: background 0s;
  }

  &.active {
    background-color: var(--invertedText);
    color: var(--primary);
  }
}