@import "ui-components/_variables.scss";
@import "ui-components/_mixins.scss";

$heightDesktop: 533px;
$heightMobile: 225px;
$contentMaxWidth: 485px;
$fontSizeTitleDesktop: size("font--large") * 2;
$fontSizeSubTitleDesktop: size("font--large");
$fontSizeTitleMobile: size("font--large");
$fontSizeSubTitleMobile: size("font");

.block {
  height: $heightDesktop;
  background-size: cover;
  background-position: left center ;
  display: flex;
  align-items: center;

  @media (max-width: (breakpoint("small") - 1px)) {
    height: $heightMobile;
  }
}

.container {
  color: white;
  max-width: $contentMaxWidth;

  .title {
    margin: 0;
    font-size: $fontSizeTitleDesktop;
  }

  .content {
    font-size: $fontSizeSubTitleDesktop;
    margin-top: size("gutter--medium-large");
    margin-bottom: 0;
  }

  @media (max-width: (breakpoint("small") - 1px)) {
    max-width: none;

    .title {
      font-size: $fontSizeTitleMobile;
    }

    .content {
      font-size: $fontSizeSubTitleMobile;
      margin-top: size("gutter--small");
    }
  }
}



