@import "ui-components/_variables.scss";
@import "ui-components/_mixins.scss";

.block {
	margin-top: -#{size("header-margin")} !important;
}

.content {
	display: grid;
	grid-template-columns: 1fr 1fr;
	gap: size("gutter--medium");
	margin-bottom: size("gutter--huge");

	@media (max-width: (breakpoint("medium") - 1px)) {
        grid-template-columns: 1fr;
		gap: size("gutter--larger");
	}
}

.wrapper {
	margin-top: size("gutter--huge");
	@media (max-width: (breakpoint("small") - 1px)) {
		margin-top: size("gutter--large");
	}
}

.form {
	height: fit-content;
}