@use "sass:math";

@import "ui-components/_variables.scss";
@import "ui-components/_mixins.scss";

.item {
  min-width: 0;
  flex: 0 0 25%;
  padding: math.div(size("grid-gutter"), 2);

  &.small {
    flex: 0 0 12.5%;
  }

  @media (max-width: (breakpoint("medium") - 1px)) {
    flex: 0 0 33.333%;
  }

  @media (max-width: (breakpoint("small") - 1px)) {
    flex: 0 0 50%;
  }
}
