@use "sass:math";

@import "ui-components/_variables.scss";
@import "ui-components/_mixins.scss";

:export{
  medium: breakpoint("medium");
  small: breakpoint("small");
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.banner {
  margin-top: size("gutter--section");

  h2 {
    font-size: 2rem;
  }
}

.hero {
  min-height: 421px;
}

.offerListItem {
  animation: fadeIn transition("duration--organisms") transition("ease-ease") forwards;
  opacity: 0;

  @media (min-width: breakpoint("small")) {
    display: flex;
    align-items: stretch;
    flex: 1 1 50%;
  }

  @media (min-width: breakpoint("medium")) {
    flex: 1 1 33.33%;
  }

  padding-left: #{math.div(size("gutter"), 2)};
  padding-right: #{math.div(size("gutter"), 2)};
  padding-bottom: size("gutter") !important;

  .count__1 & {
    flex: 1 0 auto;
    padding: 0;
  }
}

.carousel {
  margin-left: -#{math.div(size("gutter"), 2)};
  margin-right: -#{math.div(size("gutter"), 2)};
  min-width: calc(100% + #{size("gutter")});
}
