@import "ui-components/_variables.scss";
@import "ui-components/_mixins.scss";


.searchInput {
  .searchInputInput{

    input {
      border: 1px solid var(--border);
    }
  }

  .searchIcon {
    width: 100%;
    height: 100%;
    fill: var(--text);
    user-select: none;
  }

  .clear {
    position: absolute;
    right: 8px;
    top: 19px;
    background: none;
    border: none;
    padding: 0;
    color: var(--text);
  }

  .clearIcon {
    cursor: pointer;
    user-select: none;
  }
}
