@import "ui-components/_variables.scss";
@import "ui-components/_mixins.scss";

.section {
  margin-bottom: size("gutter--large");
}

.sectionTitle, .title {
  margin-bottom: 0;
}

.links {
  display: flex;
  flex-wrap: wrap;
}

.link {
  width: 25%;
  margin: size("gutter--small") 0;
  color: var(--text);
}

.separator {
  background-color: var(--borderMuted);
  height: 1px;
  width: 100%;
  margin: size("gutter--large") 0;
}
