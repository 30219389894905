@import "ui-components/_variables.scss";

.container {
  display: flex;
  flex-direction: column;
  flex: 1 0 auto;
  position: fixed;
  height: size("header-height") !important;
  z-index: z-index("system");
  top: 0;
  left: 0;
  right: 0;

  a {
    color: var(--primaryAlt);
  }

  &:not(.active) a,
  &:not(.active) a span,
  &.fullMenu.active a,
  &.fullMenu.active a span {
    color: var(--invertedText);
  }

  &.fullMenu {
    margin-bottom: size("gutter--large") * 2;
    background: var(--primaryAlt);
  }

  @media (max-width: breakpoint("small")) {
    &.hasMobileSubNav {
      height: size("header-height") + size("sub-header-height") !important;
    }
  }

  @media (min-width: breakpoint("small")) {
    &.fullMenu {
      height: size("header-height") + size("sub-header-height") !important;
    }

    &.empty {
      height: size("header-height") !important;
    }
  }

  &.active {
    box-shadow: -1px 3px 15px 0 rgba(141, 151, 166, .16);
  }


  &:not(.fullMenu).active {
    background: var(--light);

    // &:not(.hamburgerOpen) svg {
    //   color: var(--primaryAlt);
    // }
  }
}
