@use "sass:math";

@import "ui-components/_variables.scss";
@import "ui-components/_mixins.scss";

.banner {
  margin-top: size("gutter--section");

  h2 {
    font-size: 2rem;
  }
}

.a {
  @media (min-width: breakpoint("small")) {
    display: flex;
    @include stretch(size("gutter--large"));

    & > div {
      padding: 0 size("gutter--large");
    }
  }

  @media (max-width: (breakpoint("small") - 1px)) {
    & > div + div {
      margin-top: size("gutter");
    }
  }
}

.b {
  img {
    width: 100%;
  }
}

.c {
  max-width: math.div(breakpoint("large"), 2);
}
