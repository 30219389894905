@import "ui-components/_variables.scss";
@import "ui-components/_mixins.scss";

.block {
  background-color: var(--light);
  color: var(--text);
  padding: size("gutter");
  border-radius: var(--borderRadius);
  flex: 1;
}

.noScriptParagraph {
  text-align: center;
  padding-top: size("gutter");
  color: color("negative");
}

.input {
  &:not(:first-child) {
    margin-bottom: size("gutter--small");
  };
  &Block {
    display: block;
    padding-bottom: 1.8rem;
  }
  &LabelElement {
    display: block;
    margin-bottom: 0.6333333333rem;
  }
  &Element {
    width: 100%;
    height: 3.8rem;
    font-size: 16px;
    line-height: 3.8rem;
    padding-left: 1rem;
    padding-right: 1rem;
    letter-spacing: inherit;
    background-color: #fff;
    transition: border-color .15s,color .15s;
    border: 1px solid #cecece;
    border-radius: 4px;
  }
}

.bottom {
  display: flex;
  justify-content: space-between;
  margin-top: size("gutter");

  @media (max-width: (breakpoint("mini") - 1px)) {
    flex-direction: column;
  }
}

.button {
  margin-top: size("gutter--large");
  width: 100%;
}

.no_account {
  @media (min-width: (breakpoint("mini") + 1px)) {
    text-align: right;
  }
}

.forgot_password, .no_account {
  margin-top: size("gutter--large");

  p, a {
    margin: 0;
  }

  a {
    color: var(--primary);
  }
}
