@use "sass:math";

@import "ui-components/_variables.scss";
@import "ui-components/_mixins.scss";

.block {
  transition: opacity transition("duration--atoms") transition("ease-in");

  &.loading {
    opacity: .7;
    pointer-events: none;
  }
}

.insufficientFunds {
  padding: size("gutter");
  border: 1px solid var(--border);
  border-radius: size("border-radius");
  margin-bottom: size("gutter--medium");
  transition: border-color transition("duration--atoms") transition("ease-in");

  &.warning {
    border-color: var(--border);
  }
}
