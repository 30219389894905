@import "ui-components/_variables.scss";

$delimeterMargin: .2rem;

.block {
  font-size: size("font--small");
  margin-bottom: size("gutter");
}

.delimeter {
  display: inline-block;
  color: color("text--muted");
  margin-left: $delimeterMargin;
  margin-right: $delimeterMargin;
}
