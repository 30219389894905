@import "ui-components/_variables.scss";

$blockPaddingBottom : 1.8rem;

.errors {
  color: var(--negative);
  font-size: size("font--small");
  padding-top: size("gutter--small");
}

.textAreaField {
  padding-bottom: $blockPaddingBottom;
  margin-bottom: size("gutter");

  .error {
    color: var(--negative);
    border-color: var(--negative);
  }
}

.passwordField {
  position: relative;
  padding-bottom: 0px;
}

.passwordFieldButton {
  background: transparent;
  border: none;

  svg {
    max-width: 20px;
    transform: translateX(-.5rem);
    fill: var(--primary);
  }
}

.passwordInActive {
  svg {
    max-width: 20px;
    transform: translateX(-.5rem);
    fill: #fff;
    stroke: var(--primary);
  }
}

.noColorBtn svg {
  fill: #31363F;
}

.noColorBtn.passwordInActive svg {
  fill: #fff;
  stroke: #31363F;
}