@import "ui-components/_variables.scss";
@import "ui-components/_mixins.scss";

$width: 360px;
$img-size: 64px;
$header-height: size("header-height");
$sub-header-height: size("sub-header-height");
$header-margin: size("header-margin");

@keyframes appear {
  0% { opacity: 0; transform: translateY(-20px); }
  100% { opacity: 1; transform: translateY(0); }
}

.miniCart {
  opacity: 0;
  position: absolute;
  animation: appear transition("duration--templates") transition("ease-out") forwards;
  animation-delay: transition("duration--molecules");
  display: flex;
  flex-direction: column;
  margin-top: size("grid-gutter");
  border-radius: size("border-radius");
  
  z-index: z-index("overlay");
  background: var(--light);
  box-shadow: 0 24px 64px 0 rgba(0, 0, 0, 0.1);

  max-height: calc(100svh - (#{$header-height + $sub-header-height + $header-margin} + #{size("grid-gutter") * 2}));

  @media (max-width: (breakpoint("small") - 1px)) {
    max-height: calc(100svh - (#{$header-height} + #{size("grid-gutter") * 2}));
  }

  @media (max-width: (breakpoint("small") - 1px)) {
    right: size("gutter");
    left: size("gutter");
  }

  @media (min-width: breakpoint("small")) {
    width: $width;
    right: size("gutter");
  }

  table {
    width: 100%;
  }

  td {
    vertical-align: top;

    &:last-child {
      text-align: right;
      line-height: 1.3;
    }
  }

  hr {
    border-color: color("disabled");
    margin: size("gutter") 0;
  }
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: size("gutter--small");
  border-bottom: 1px solid color("border");

  .close {
    svg {
      width: 25px;
      height: 25px;
    }
  }
}

.list_container {
  position: relative;
  flex: 1 1 auto;
  min-height: 0; // needed to make overflow in .list work
  display: flex;
  flex-direction: column;
  overflow-y: hidden;
}

.list {
  flex: 1;
  overflow-y: auto;
}

.shadow {
  &_top,
  &_bottom {
    position: absolute;
    left: 0;
    width: 100%;
    height: 1px;
    background-color: rgba(0, 0, 0, 0.035);
    z-index: 1;
    transition: opacity transition("duration") transition("ease-ease");
  }

  &_top {
    box-shadow: 0 0 24px 0 rgba(0, 0, 0, 1);
    top: 0;
  }

  &_bottom {
    box-shadow: 0 0 24px 0 rgba(0, 0, 0, 1);
    bottom: 0;
  }

  &__hidden {
    opacity: 0;
  }
}

.item {
  padding: size("gutter--small");
  transition: opacity transition("duration--atoms") transition("ease-in");

  & + & {
    border-top: 1px solid color("border");
  }

  &Body {
    display: flex;
    align-items: center;
    min-width: 0;
  }

  &Img {
    border-radius: size("border-radius");
    margin-right: size("gutter--small");
    margin-top: auto;
    margin-bottom: auto;
    width: $img-size;
    height: $img-size;
    align-self: flex-start;
  }

  &TextContainer {
    min-width: 0; // Hack to allow ellipsis inside flex parent
    flex: 1;
  }

  &Name {
    font-weight: 700;
    margin-bottom: 0;
    line-height: font("line-height--heading");

    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }

  &Brand {
    color: color("text--muted");
    font-size: size("font--small");
    margin-top: 0;
    margin-bottom: 0;
  }

  &Qty {
    margin-top: 0;
    font-size: size("font--small");
  }
}

.totals {
  border-top: 1px solid color("border");
  padding: size("gutter");
  color: var(--text);
  flex: 0;

  &__zupergift {
    margin-bottom: size("gutter");
    border-bottom: 1px solid color("border");
  }

  &Row {
    display: flex;
    justify-content: space-between;

    &__zupergift {
      justify-content: center;
      text-align: center;
    }

    &__grandtotal {
      padding-top: size("gutter");

      &:last-child {
        line-height: font("line-height");
        text-align: right;
      }
    }

    & + & {
      margin-top: size("gutter");
    }
  }
}

.bottom {
  display: flex;
  flex-direction: column;
  gap: size("grid-gutter");
  padding: size("gutter--small");
  padding-top: 0;

  button {
    width: 100%;
  }

  a {
    display: block;
  }
}
