@import "ui-components/_variables.scss";
@import "ui-components/_mixins.scss";

$paddingTopLarge: 250px;
$paddingTopSmall: 150px;
$maxWidthLoginForm: 425px;

:export {
  small: strip-unit(breakpoint(small));
}

.header {

  &Wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: size("header-height");

    @media (min-width: breakpoint("medium")) {
      padding-top: size("gutter--large");
    }
  }

  a {
    color: var(--light);
    font-weight: bold;
    font-size: size("font--medium-small");
  }
}

.block {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  flex: 1 0 auto;
  color: var(--invertedText);
}

.wrapper {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}

.footer {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: size("grid-gutter");
  padding: size("gutter") 0;
  color: var(--invertedText),
}

.footerLinks {
  display: flex;
  justify-content: center;
  width: 100%;
  margin-left: size("gutter");

  li + li {
    margin-left: size("gutter");
  }

  a {
    color: var(--invertedText);
  }
}
