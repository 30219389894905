@import "ui-components/_variables.scss";
@import "ui-components/_mixins.scss";

$checkmarkMarginRight: .3rem;

.value {
  padding: size("gutter") size("gutter--large");
  border-top: 1px solid var(--borderMuted);
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: color("body");

  &:hover {
    background-color: var(--primary-l1) !important;
    color: var(--invertedText) !important;

    .checkmark {
      fill: var(--invertedText);
      stroke: var(--invertedText);
      color: var(--light) !important;
    }
  }

  &:first-child {
    border-top: none;
  }

  .checkmark {
    fill: var(--primary);
    stroke: var(--primary);
    position: absolute;
    right: size("gutter--large");
  }
}
