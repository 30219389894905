@use "sass:math";

@import "ui-components/_variables.scss";
@import "ui-components/_mixins.scss";
@import "../../../../node_modules/@crossroads/ui-components/src/ui-components/Input.scss";

.row {
  @media (min-width: breakpoint("small")) {
    @include stretch(math.div(size("gutter--small"), 4));
    @include clearfix();
  }
}

.s50 {
  @media (min-width: breakpoint("small")) {
    width: 50%;
    padding: 0 math.div(size("gutter--small"), 4);
    float: left;
  }
}
