@use "sass:math";

@import "ui-components/_variables.scss";
@import "ui-components/_mixins.scss";
@import "../../../../node_modules/@crossroads/ui-components/src/ui-components/Input.scss";

.block {
  display: block;
}

.label {
  display: block;
  margin-bottom: math.div(size("gutter--small"), 1.5);

  .error & {
    color: var(--negative) !important;
  }
}

.dropdown {
  display: block;

  [disabled] {
    cursor: not-allowed;
  }

  & > button {
    height: $height;
  }
}

.inputErrors {
  top: calc(100% + .2rem);
}
