@import "ui-components/_variables.scss";
@import "ui-components/_mixins.scss";

.item {
  box-shadow: 5px 5px 15px 1px rgb(0 0 0 / 10%);

  a {
    color: var(--text);

    &:hover {
      text-decoration: none;
    }
  }

  h3 {
    margin-bottom: size("gutter--mini");
  }
}

.itemContent {
  padding: size("gutter");
}