@use "sass:math";

@import "ui-components/_variables.scss";
@import "ui-components/_mixins.scss";
@import "../../../../node_modules/@crossroads/ui-components/src/ui-components/Input.scss";

.accountForm {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: size("gutter");

  @media (max-width: breakpoint("small")) {
    grid-template-columns: 1fr;
  }
}

.dropdown {
  display: block;
  
  [disabled] {
    cursor: not-allowed;
  }

  & > button {
    height: $height;
  }

  &__maxHeight {
    main {
      max-height: 300px;
      overflow-y: scroll;
    }
  }
}
