@import "ui-components/_variables.scss";
@import "ui-components/_mixins.scss";

$tableMinHeight: 200px;

@keyframes shine {
  0% {
    background-position: -100%;
  }

  100% {
    background-position: 100%;
  }
}

.tableLarge {
  width: 100%;
  min-height: $tableMinHeight;
  display: flex;
  flex-direction: column;
  padding: 0;

  @media (max-width: (breakpoint("small") - 1px)) {
    display: none;
  }

  table {
    table-layout: fixed;
  }

  tr td {
    border-top: 1px solid var(--border);
  }
}

.tableHintLarge {
  margin-top: size("gutter--large");
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: $tableMinHeight;

  @media (max-width: (breakpoint("small") - 1px)) {
    display: none;
  }
}
