@use "sass:math";

@import "ui-components/_variables.scss";
@import "ui-components/_mixins.scss";
@import "../../../../node_modules/@crossroads/ui-components/src/ui-components/Input.scss";

.row {
  @media (min-width: breakpoint("small")) {
    @include stretch(math.div(size("gutter--small"), 4));
    @include clearfix();
  }
}

.s100, .s50, .s33 {
  @media (min-width: breakpoint("small")) {
    padding: 0 math.div(size("gutter--small"), 4);
    float: left;
  }
}

.s100 {
  @media (min-width: breakpoint("small")) {
    width: 100%;
  }
}

.s50 {
  @media (min-width: breakpoint("small")) {
    width: 50%;
  }
}

.s33 {
  @media (min-width: breakpoint("small")) {
    width: 33.333%;
  }
}

.dropdown {
  display: block;

  [disabled] {
    cursor: not-allowed;
  }

  & > button {
    height: $height;
  }
}
