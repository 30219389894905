@import "ui-components/_variables.scss";

.block {
  display: flex;
  align-items: center;
  overflow: hidden;
  min-width: 1px;

  img {
    max-height: 64px;
  }
}

.name {
  font-family: font("header");
  font-size: size("font--medium");
}

.svg {
  color: var(--light);
}
