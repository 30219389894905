@import "ui-components/_variables.scss";
@import "ui-components/_mixins.scss";

.item {
  display: flex;
  align-items: center;
  font-family: var(--fontHeader), var(--fontHeaderFallback, #{font("fallback")});
  font-size: 1.25rem;
  margin-left: 0;
  width: 100%;
  padding: 0 size("gutter");
  border-bottom: 1px solid rgba(255,255,255, 0.4);
  min-height: size("ooc-row-height");
  color: var(--light);
  font-weight: 700;
  cursor: pointer;
  user-select: none;
}
