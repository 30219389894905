@import "ui-components/_variables.scss";
@import "ui-components/_mixins.scss";

$image-container-size: 290px;

.wrapper {
  height: 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  border-radius: var(--borderRadius);
  line-height: font("line-height--medium-small");

  @media (max-width: (breakpoint("large") + 10px)) {
    padding: size("gutter--large") size("gutter");
  }

  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: size("gutter--medium") 0 size("gutter--larger") 0;

    h2 {
      margin-bottom: 0;
      line-height: initial;
    }

    .btn {
      padding: size("gutter--small") !important;
      position: relative;
    }
  }

  .imagesBlock {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: repeat(4, 1fr);
    width: 100%;
    grid-gap: size("gutter--mini");

    @media (max-width: (breakpoint("large") + 1px)) {
      grid-template-columns: repeat(3, 1fr);
      grid-template-rows: repeat(3, 1fr);
    }

    @media (max-width: (breakpoint("medium") + 1px)) {
      grid-template-columns: repeat(2, 1fr);
      grid-template-rows: repeat(2, 1fr);
    }

    @media (max-width: (breakpoint("small") + 1px)) {
      grid-template-columns: repeat(1, 1fr);
      grid-template-rows: repeat(1, 1fr);
    }

    .imageContainerBox {
      margin: size("gutter--small") 0;
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
      align-items: center;
      justify-content: center;

      .imageContainer {
        width: $image-container-size;
        height: $image-container-size;
        border-radius: calc(size("gutter--small") / 2);
        cursor: pointer;

        img {
          height: 100%;
        }
      }
    }

    .imageContainerTitle {
      width: 30ch;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }
}

.lightbox {
  width: 100%;
  height: 100%;
  padding: 0 size("gutter--huge") size("gutter--huge");
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;

  @media (max-width: (breakpoint("medium") + 1px)) {
    padding: 0 size("gutter") size("gutter");
  }

  svg {
    cursor: pointer;
    flex-shrink: 0;
  }

  .lightboxImage {
    height: 100%;
    width: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    img {
      max-height: 700px;
      height: 100%;

      @media (max-width: (breakpoint("medium") + 1px)) {
        max-height: auto;
        width: 100%;
      }
    }
  }
}


.description {
  max-width: 100ch;
  margin-bottom: size("gutter--larger");
  
  ul {
    line-height: font("line-height");
    list-style-type: disc;
    margin-left: size("gutter");
  }
}

.uploadedImagesContainer {
  justify-content: flex-start;

  .uploadedImageContainer {
    margin-bottom: size("gutter--mini");
    cursor: pointer;

    @media (max-width: (breakpoint("mini") + 20px)) {
      flex: 0 0 100%;
    }
  }

  .innerImageContainer {
    height: 290px;
    overflow: hidden;

    img {
      object-fit: cover;
      height: 100%;
      width: 100%;
    }
  }

  .imageContainerTitle {
    max-width: 90%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
}