@import "ui-components/_variables.scss";
@import "ui-components/_mixins.scss";

$submitHeight: 62px;
$selectionColor: #fbfbfb;

.content {
  z-index: 1;
  color: var(--text);

  @media (max-width: breakpoint("small")) {
    overflow: auto;

    .form:not(#foo) {
      grid-template-columns: repeat(1, 2fr);
    }
  }

  @media (min-width: breakpoint("medium")) {
    width: 80%;
  }
}

.formWrapper {
  padding-top: size("gutter--medium");

  .form {
    display: grid;
    width: 100%;
    background-color: $selectionColor;
    padding: size("gutter--small") size("gutter--small") 0;
    grid-template-columns: repeat(3, 1fr);
    column-gap: size("gutter--small");
    row-gap: size("grid-gutter");
    align-items: center;
    border-radius: var(--borderRadius);
    border: 1px solid var(--border);

    .checkboxWrapper {
      display: flex;
      gap: size("gutter--small");
    }

    @media (max-width: breakpoint("small")) {
      padding: size("gutter--small");

      .checkboxWrapper {
        padding-bottom: size("gutter");
        gap: size("gutter--large");
      }
    }

    .field {
      margin-bottom: size("gutter--large");
      padding-bottom: 0;
    }
  }
}

.submitWrapper {
  display: flex;
  width: 100%;
  height: 100%;
  justify-self: flex-end;
  justify-content: flex-end;

  button {
    height: $submitHeight;
    margin-bottom: size("gutter--large");
    width: 100%;
  }
}