@import "ui-components/_variables.scss";
@import "ui-components/_mixins.scss";

.table {
  tbody, .tbody {
    border: 1px solid var(--border);
    border-radius: var(--borderRadius);
  }

  tr td, .tr {
    padding: size("gutter--small");
  }

  tr td, .tr:not(:first-child) {
    border-top: 1px solid var(--border);
  }

  thead th, .th {
    padding: size("gutter--small");
    font-weight: normal;
    font-size: size("font--small");
    text-align: left;
  }
}