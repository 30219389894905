@use "sass:math";

@import "../../node_modules/@crossroads/shop-views/src/shop-views/StripeView.scss";

@import "ui-components/_variables.scss";
@import "ui-components/_mixins.scss";

.heading {
  margin: size("gutter--large") 0;
}

.item, .summaryRow {
  display: flex;
  justify-content: space-between;
  padding: size("gutter");
  border-radius: size("border-radius");
  border: 1px solid color("border--muted");

  & + & {
    margin-top: size("grid-gutter");
  }
}

.items, .summary {
  border-radius: size("border-radius");
}

.item {
  background: #fff;
}

.left {
  position: static;

  @media (min-width: breakpoint("medium")) {
    display: flex;
  }
}

.right {
  display: flex;
  align-items: center;
}

.image {
  width: 80px;
  height: 80px;
  border-radius: calc(#{size("border-radius")} / 2);

  @media (max-width: (breakpoint("medium") - 1px)) {
    float: left;
    margin-right: size("gutter--small");
  }
}

.info {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-left: size("gutter");
  margin-right: size("gutter");
}

.name {
  margin: 0;
}

.loading {
  padding: size("gutter");
}

.brand {
  color: color("text--muted");
  margin: 0;
}

.price {
  color: color("text--muted");
  font-size: size("font--small");
}

.summaryRow .left .info {
  margin-left: 0;
}

.link {
  display: flex;
  align-items: center;

  img {
    filter: contrast(0.4);
    padding-top: 1px;
  }
}

.summaryRow {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #fff;
  padding: size("gutter");
  border-radius: size("border-radius");

  .title {
    margin-bottom: 0;
  }

  .subTitle {
    color: color("text--muted");
    margin-top: 0;

    .emptyAddress {
      color: color("warning");
    }
  }

  & + & {
    margin-top: size("grid-gutter");
  }
}

.accordion {
  border-radius: size("border-radius");
  overflow: hidden;

  &Header {
    background: #fff;
    font-weight: bold;
    text-transform: uppercase;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: size("gutter--small");
    margin-bottom: size("grid-gutter");
    border: 1px solid color("border--muted");
    border-radius: size("border-radius");

    user-select: none;

    span {
      font-weight: normal;
      color: color("text--muted");
    }
  }

  .closeIcon {
    transition: transform transition("duration--organisms") transition("ease-in");
    color: var(--primary);;
    width: 24px;
    height: 24px;

    &.open {
      transform: scaleY(-1);
    }
}

  @media (min-width: breakpoint("small")) {
    &Header {
      display: none;
    }

    & > div[data-foldable=true] {
      overflow: auto !important;
      height: auto !important;
    }
  }
}

.otp {
  padding: size("gutter");

  button {
    width: 100%;
  }
}

.paymentMethods {
  padding: size("gutter");

  h2 {
    font-size: size("font--small");
    color: color("text");
  }
}

// Container styling

.container {
  &Block {
    @media (min-width: breakpoint("small")) {
      display: flex;
      @include stretch();
      @include clearfix();
    }
  }

  &Left {
    @media (min-width: breakpoint("small")) {
      padding-right: math.div(size("gutter--large"), 2);
      float: left;
      width: 61%;
    }
  }

  &Right {
    @media (max-width: (breakpoint("small") - 1px)) {
      margin-top: size("gutter--large");
    }

    @media (min-width: breakpoint("small")) {
      padding-left: math.div(size("gutter--large"), 2);
      float: right;
      flex: 0 0 auto;
      width: 39%;
    }
  }
}
