@use "sass:math";

@import "ui-components/_variables.scss";
@import "ui-components/_mixins.scss";

@keyframes shine {
  0% {
    background-position: -100%;
  }
  100% {
    background-position: 100%;
  }
}

.block {
  padding-bottom: size("gutter--huge");

  .link {
    &:hover {
      text-decoration: none;
    }
  }
}

.text {
  line-height: font("line-height--paragraph");
}

.split {
  display: flex;

  @media (min-width: breakpoint("small")) {
    @include stretch(math.div(size("gutter--large"), 2));

    & > * {
      flex: 1 0 50%;
      padding: 0 math.div(size("gutter--large"), 2);
    }
  }

  @media (max-width: (breakpoint("small") - 1px)) {
    flex-direction: column;

    & > *:first-child {
      order: 2;
    }
    & > *:last-child {
      order: 1;
    }
  }
}

.right {
  display: flex;
  justify-content: center;
  align-items: flex-start;
}

.logo {
  display: block;

  &Mobile {
    margin: #{size("gutter--large") * 1.5} auto;
  }

  @media (max-width: (breakpoint("small") - 1px)) {
    &Desktop {
      display: none;
    }
  }

  @media (min-width: breakpoint("small")) {
    &Mobile {
      display: none;
    }
  }
}

.links {
  margin-top: size("gutter--large");

  & > * {
    width: 100%;
    display: inline-block;

    @media (min-width: breakpoint("small")) {
      width: auto;
    }
  }

  & > * + * {
    margin-top: size("gutter--small");

    @media (min-width: breakpoint("small")) {
      margin-left: size("gutter--medium");
    }
  }

  .buttonLink {
    width: 100%;

     & > div {
      line-height: 0.8;
    }
  }
}

.commissions {
  color: var(--textMuted);
  margin-top: size("gutter--large");
  list-style: disc;
  padding-left: 1.1rem;

  & > li {
    margin-top: size("gutter--mini");
  }
}

.terms {
  margin-top: size("gutter--large");
  width: 50%;
  padding-right: math.div(size("gutter--large"), 2);

  @media (max-width: (breakpoint("small") - 1px)) {
    width: 100%;
  }
}

.commissionsHint, .termsHint, .textHint {
  height: (size("font") + size("gutter--mini")) * 3;
  background-image: linear-gradient(90deg, color("disabled") 0%, color("background") 50%, color("disabled") 100%);
  background-size: 200%;
  animation: shine 1.5s infinite linear;
  border-radius: var(--borderRadius);
}

.textHint {
  height: (size("font") + size("gutter--mini")) * 5;
}
