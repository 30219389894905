@import "ui-components/_variables.scss";
@import "ui-components/_mixins.scss";

.block {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: size("gutter--large") * 2;
  padding-bottom: size("gutter--large") * 2;
}

.fullScreen {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: size("gutter--large") * 2;
  padding-bottom: size("gutter--large") * 2;
  height: 100vh;
  width: 100vw;
}
