@import "ui-components/_variables.scss";
@import "ui-components/_mixins.scss";

.block {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;

  @include stretch(size("grid-gutter"));
}

.item {
  flex: 0 0 50%;
  min-width: 0;

  .block__perRow4 & {
    @media (min-width: breakpoint("small")) {
      flex: 0 0 33.33%;
    }

    @media (min-width: breakpoint("medium")) {
      flex: 0 0 25%;
    }
  }
}

.itemInner {
  padding: size("grid-gutter");
  height: 100%;
}
