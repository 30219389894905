@import "ui-components/_variables.scss";
@import "ui-components/_mixins.scss";

$iconSideLength: 36px;

.block {
  transition: all transition("duration--atoms") transition("ease-in");

  &.warning {
    background-color: var(--negative-o1);
  }

  h2,
  p {
    margin: 0;
  }

  .top {
    display: flex;
    align-items: center;

    & > .icon {
      height: $iconSideLength;
      min-height: $iconSideLength;
      width: $iconSideLength;
      min-width: $iconSideLength;
      margin-right: size("gutter--large");
    }

    h2 {
      font-size: size("font--medium-small");

      @media (min-width: breakpoint("medium")) {
        font-size: size("font--large");
      }
    }
  }

  .middle {
    margin-top: size("gutter--small");
  }

  .bottom {
    margin-top: size("gutter");

    &Label {
      font-size: size("font--small");
      line-height: 1.43;
    }

    &Points {
      line-height: 1.33;
    }
  }
}
