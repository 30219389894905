@use "sass:math";

@import "ui-components/_variables.scss";

:export {
  mini: strip-unit(breakpoint("mini"));
  small: strip-unit(breakpoint("small"));
  medium: strip-unit(breakpoint("medium"));
  large: strip-unit(breakpoint("large"));
}

.block {
  margin-top: -#{size("header-height")};
}

.promotion {
  display: flex;
  content-visibility: auto;

  .left,
  .right {
    width: 50%;
  }

  .left {
    margin-bottom: 0;
    margin-right: size("gutter--large");
  }

  .right {
    margin-left: size("gutter--large");
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-around;
    padding: size("gutter--large");
  }

  @media (max-width: (breakpoint("medium") - 1px)) {
    flex-direction: column;

    .left,
    .right {
      width: 100%;
      margin: initial;
    }

    .left {
      &:not(.empty) {
        margin-bottom: size("gutter");
      }
    }
  }
}

.section {
  display: flex;
  flex-direction: column;
  justify-content: center;
  content-visibility: auto;

  .title,
  .description {
    max-width: 650px;
  }
}

.cta {
  font-size: size("font--medium");
  text-transform: uppercase;

  &Large {
    font-size: size("font--large");
  }
}

.popularCategories,
.popularProducts {
  padding: 0;
}

.featuredProducts,
.earnOnline,
.currentPageInfo {
  margin-top: size("gutter--large");
  display: flex;
  justify-content: center;
}

.currentPageInfo {
  flex-wrap: wrap;
  margin-left: -#{math.div(size("gutter--mini"), 2)};
  margin-right: -#{math.div(size("gutter--mini"), 2)};
}

.currentPageInfoItem {
  width: calc(50% - #{size("gutter--mini")});
  display: flex;
  flex-direction: column;
  border: 1px solid var(--border);
  border-radius: var(--borderRadius);
  margin: math.div(size("gutter--mini"), 2);
  padding: size("gutter--large") 0;
  left: auto;

  @media (max-width: (breakpoint("small") - 1px)) {
    width: 100%;
    padding: size("gutter--large");
    margin: math.div(size("gutter"), 2) math.div(size("gutter--mini"), 2);
  }
}

.featuredProducts {
  flex-direction: column;
}

.featuredCTA {
  text-align: center;
  margin-top: size("gutter");
}

@media (max-width: (breakpoint("tiny") - 1px)) {
  .cta,
  .ctaLarge {
    font-size: size("font") !important;
  }
}

.wrapper {
  margin-top: size("gutter--module");

  @media (max-width: (breakpoint("medium") - 1px)) {
    margin-top: size("gutter--huge");
  }
  @media (max-width: (breakpoint("tiny") - 1px)) {
    margin-top: size("gutter--huge") 0;
  }

  & > *:not(:first-child) {
    margin-top: size("gutter--module");
  }
}

.currenInfoCardsSection {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: size("gutter");

  @media (min-width: breakpoint("medium")) {
    grid-template-columns: 1fr 1fr 1fr;
  }
}

.currenInfoCardImage {
  display: flex;
  justify-content: center;
  margin: size("grid-gutter") 0;
}

.currenInfoCardsSectionCardWrapper {
  display: flex;
  flex-wrap: wrap;
  flex: 1 1 auto;
  margin: 0;

  @media (max-width: (breakpoint("medium") - 1px)) {
    flex: 0 0 100%;
  }
}

.currentInfoCard {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: var(--light);
  color: var(--text);
  box-shadow: 5px 5px 15px 1px rgba(0, 0, 0, 0.1);
  flex-grow: 1;
  transition: transition("duration--atoms") transition("ease-in");
  padding: size("gutter--large");

  @media (max-width: (breakpoint("small") - 1px)) {
    padding: size("gutter");
  }

  img {
    width: 100%;
    margin-bottom: size("grid-gutter");
  }

  h3 {
    margin-bottom: size("grid-gutter");
  }

  p {
    margin-top: 0;
  }

  &Content {
    padding: size("gutter");
  }
}

.currentInfoDescription {
  font-size: size("font--small");
  max-width: 600px;
  display: flex;
  flex-direction: column;

  p:last-child {
    justify-self: end;
  }

  a {
    display: table;
    background-color: var(--primary);
    color: var(--light);
    padding: size("gutter--mini") * 1.55 size("gutter") size("gutter--mini") * 1.6;
    margin-top: size("gutter");
    border-radius: var(--borderRadius);
  }
}
