@import "ui-components/_variables.scss";
@import "ui-components/_mixins.scss";

$inputHeight: 3.8rem;

.block {
  .boxHeader {
    border: 0;
    text-transform: none;

    label {
      cursor: pointer;
    }

    & + main {
      border-top: 1px solid color("border--muted");
    }
  }

  .discountCode {
    display: flex;
    align-items: flex-end;

    button {
      height: $inputHeight;
    }

    & > :first-child {
      flex: 1 0 auto;
      margin-right: size("gutter");
    }

    @media (max-width: (breakpoint("small") - 1px)) {
      flex-direction: column;

      & > * {
        width: 100%;
      }

      & > :first-child {
        margin-right: auto;
      }

      button {
        margin-top: size("gutter");
      }
    }
  }
}
