@import "ui-components/_variables.scss";

$searchableDropdownHeight: 3.8rem;
$paddingX: 1rem;
$floatLabelOffset: -.8rem;
$floatLabelSize: 0.88em;

.errors {
  color: color("negative");
  font-size: size("font--small");
  padding-top: size("gutter--small");
}

.searchableDropdown {
  height: $searchableDropdownHeight;

  &Label {
    position: absolute;
    color: color("text--muted");
    pointer-events: none;
    left: $paddingX;
    top: 0;
    height: 100%;
    transform: translateY(0);
    transition: transform transition("duration--molecules"), font-size transition("duration--molecules");
    display: flex;
    align-items: center;
  }

  div[data-open=true] + &Label, &Label__active {
    font-size: $floatLabelSize;
    transform: translateY($floatLabelOffset);
    font-weight: 400;
  }
}

