@import "ui-components/_variables.scss";
@import "ui-components/_mixins.scss";

.box {
  background: #fff;
  border: 1px solid color("border--muted");
  border-radius: size("border-radius");

  & + & {
    margin-top: size("gutter");;
  }

  &Header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: size("gutter--mini") size("gutter");
    border-bottom: 1px solid color("border--muted");
    color: var(--primary);

    // offset bad font y-align
    & > h2 {
      top: 5px;
      height: 1.6em;
    }

    & > h2, & > span {
      text-decoration: underline;
    }

    & > * {
      margin: 0;
    }

    &.interactive {
      cursor: pointer;
      user-select: none
    }
  }

  &Body {
    padding: size("gutter");
  }
}
