@import "ui-components/_variables.scss";
@import "ui-components/_mixins.scss";

$height: 1rem + (size("input-padding") * 2);
$blockPaddingBottom: 1.8rem;

.controls {
  @media (min-width: breakpoint("small")) {
    display: flex;
    justify-content: space-between;
  }

  @media (max-width: (breakpoint("small") - 1px)) {
    & > * {
      width: 100%;

      &:not(:first-child) {
        margin-top: size("gutter");
      }
    }
  }
}

.left {
  .searchInput {
    width: 260px;
    display: flex;
    align-items: center;
  }

  .searchIcon {
    width: 22px;
    height: 22px;
    fill: var(--text);
    user-select: none;
  }

  .clear {
    background: none;
    border: none;
    padding: 0;
    color: var(--text);
  }

  .clearIcon {
    cursor: pointer;
    user-select: none;
  }

   @media (max-width: (breakpoint("small") - 1px)) {
    & > div, .searchInput {
      width: 100%;
    }
  }
}

.right {
  .datePicker, .fitlerPicker {
    min-width: 250px;
    & > button {
      height: $height;
    }
  }

  .datePicker {
    margin-left: size("gutter");
  }

  @media (max-width: (breakpoint("small") - 1px)) {
    flex-direction: column;

    & > * {
      width: 100%;
      min-width: none;
    }

    .datePicker, .fitlerPicker {
      margin-bottom: $blockPaddingBottom;
    }

    .datePicker {
      margin-left: 0;
      margin-top: size("gutter");
    }
  }
}
