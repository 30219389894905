@import "ui-components/_variables.scss";
@import "ui-components/_mixins.scss";

.back {
  display: inline-flex;
  align-items: center;
  color: var(--text);
  left: -#{size("grid-gutter")};

  svg {
    transform: rotate(180deg);
    margin-right: size("gutter--mini");
  }
}
