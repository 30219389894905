@import "ui-components/_variables.scss";
@import "ui-components/_mixins.scss";

.block {
  display: flex;
  align-items: center;

  img {
    max-width: 42px;
  }

  .customerInfo {
    margin-left: size("gutter--mini");
  }

  .slabBackground {
    background: var(--light);
    padding: size("gutter--mini");
    display: flex;
    align-items: center;
    justify-content: center;
    width: 60px;
    height: 60px;
    border-radius: 50%;
  }

  .slabLarge {
    img {
      max-width: 60px;
    }
  }

  p {
    margin-top: 0;
    margin-bottom: 0;
    line-height: 1.2;
    text-transform: none;
    font-weight: initial;

    &:first-child {
      font-size: size("font");
    }

    &:last-child {
      font-size: size("font--small");
    }
  }
}
