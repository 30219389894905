@import "ui-components/_variables.scss";
@import "ui-components/_mixins.scss";

.header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  @media (max-width: (breakpoint("small") - 1px)) {
    flex-direction: column;
  }
}

.body {

}

.section {
  margin-bottom: size("gutter--large");
}

.sectionTitle, .title {
  margin-bottom: 0;
}

.clear {
  background: none;
  border: none;
  padding: 0;
  color: var(--text);
}

.searchInput {
  width: 260px;
}

.searchIcon {
  width: 100%;
  height: 100%;
  fill: var(--text);
  user-select: none;
}

.clearIcon {
  cursor: pointer;
  user-select: none;
}

.links {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  column-gap: size("gutter--large");
  row-gap: size("gutter--large");
  margin-top: size("gutter--medium");

  @media (max-width: (breakpoint("tiny") - 1px)) {
    grid-template-columns: repeat(3, 1fr);
  }

  @media (max-width: (breakpoint("mini") - 1px)) {
    grid-template-columns: repeat(2, 1fr);
  }
}

.link {
  color: var(--text);
}

.separator {
  background-color: var(--borderMuted);
  height: 1px;
  width: 100%;
  margin: size("gutter--large") 0;
}
