
@import "ui-components/_variables.scss";
@import "ui-components/_mixins.scss";

.items {
  display: flex;
  flex-wrap: wrap;

  @media (max-width: (breakpoint("medium") - 1px)) {
    justify-content: center;
  }
}
