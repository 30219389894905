
@import "ui-components/_variables.scss";
@import "ui-components/_mixins.scss";

$background: var(--light);
$header-height: size("header-height") + size("sub-header-height") + size("header-margin");

:export {
  categoriesWidth: size("categories-width");
}

.block {
  display: flex;
  font-size: size("font--small");
  max-height: calc(100vh - #{$header-height} - #{size("gutter--medium")});
  overflow-x: auto;
}

.parents {
  flex: 1 0 auto;
  width: size("categories-width");
}

.children {
  flex: 0 1 auto;

  overflow: hidden;
  transition: width transition("duration--atoms") transition("ease-in");

  &.fade {
    background: var(--border);
  }
}

.open {
  background: var(--primary-l1) !important;
  svg, .link, .subLink {
    color: var(--light) !important;
  } 
}

.item {
  display: flex;

  &:hover {
    background: var(--primary-l1) !important;
    svg, .link, .subLink {
      color: var(--light) !important;
    }
  }

}

.subLink {
  margin-left: size("gutter--mini")
}

.link, .subLink {
  flex: 1 0 auto;
  padding: size("gutter--small");
  color: var(--text) !important;

  &:hover {
    text-decoration: none;
  }
}


.button {
  flex: 0 1 auto;
  border: none;
  padding: 0 size("gutter--small");
  background: none;

  .item:hover & {
    border-left: 1px solid var(--primary-l2);
  }

  &:hover {
    background: var(--primary-l2);
  }

  .chevron {
    margin: 0;
    transition: transform transition("duration--organisms") transition("ease-ease");
    transform: rotate(90deg);

    .open & {
      transform: rotate(-90deg);
    }
  }
}
