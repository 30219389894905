@use "sass:math";

@import "ui-components/_variables.scss";
@import "ui-components/_mixins.scss";

@keyframes fadeIn {
  0% { opacity: 0; }
  100% { opacity: var(--overlayOpacity, 1); }
}

.block {
  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: size("gutter");
    border-bottom: 1px solid var(--border);
  }

  .heading {
    font-size: size("font--medium");
    margin-bottom: 0;
  }

  .body {
    padding: size("gutter");
  }

  button {
    display: flex;
    align-items: center;

    padding: 0;
    background: none;
    border: none;
    color: var(--primary-l1);

    &:hover {
      text-decoration: underline;
    }

    svg {
      margin-left: math.div(size("gutter--mini"), 2);
    }
  }
}

.recruitAFriendModal {
  position: fixed;
  top: size("header-height");
  right: 0;
  bottom: 0;
  left: 0;
  z-index: #{z-index("system") - 1};

  @media (min-width: breakpoint("tiny")) {
    top: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: calc(#{size("header-height")} + #{size("gutter")});
    padding-bottom: size("gutter");
  }

  .overlay {
    position: absolute;
    background: var(--overlayColor);
    animation: fadeIn transition("duration--organisms") transition("ease-out") forwards;
    top: calc(#{size("header-height")} + #{size("sub-header-height")});
    bottom: 0;
    left: 0;
    right: 0;

    @media (max-width: (breakpoint("tiny") - 1px)) {
      display: none;
    }
  }

  &.subMenuHidden .overlay {
    top: size("header-height");
  }

  .container {
    padding: size("gutter");
    padding-top: size("gutter--larger");
    padding-bottom: size("gutter--larger");
    border-radius: var(--borderRadius);
    box-shadow: 0 8px 15px 0 rgba(0, 0, 0, 0.1);
    background-color: #fff;
    max-height: 100%;
    min-height: 100%;
    width: 100%;
    overflow-y: auto;

    @media (min-width: breakpoint("tiny")) {
      padding: size("gutter--large");
      width: auto;
      max-width: 430px;
      min-height: auto;
    }

    .closeButton {
      position: absolute;
      right: 0.5rem;
      top: 0.5rem;
      z-index: 1;
      color: var(--primary, #000);
    }
  }

  .body {
    padding: size("gutter");
    margin: auto;

    & > * + * {
      margin-top: size("gutter");
    }

    h1 {
      text-align: center;
      font-size: size("font--large");
    }

    p {
      text-align: center;
      font-size: size("font--small");
      color: var(--textMuted);
    }
  }

  &Form {
    button {
      width: 100%;
    }
  }

  .checkmark{
    display: flex;
    justify-content: center;
    margin-top: size("gutter--large");

    &Icon{
      width: 48px;
      height: 48px;
      border-radius: 50%;
      border: 1px solid var(--border);
      display: flex;
      align-items: center;
      justify-content: center;

      svg {
        width: 34px;
        height: 34px;
        color: var(--primary)
      }
    }
  }
}

