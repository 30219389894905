@use "sass:math";

@import "../../node_modules/@crossroads/ui-components/src/ui-components/Slider.scss";
@import "ui-components/_variables.scss";

$handle-border-color: var(--primary);
$handle-bg-color: #fff;
$track-color: var(--border);
$value-color: var(--primary);

$transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
$transition-duration: 200ms;

$handle-size: 28px;
$handle-size-hover: 32px;
$top-offset: 3px;
$top-offset-hover: 1px;
$tracker-height: 6px;

$handle-size--small: 16px;
$handle-size-hover--small: 24px;
$top-offset--small: 7px;
$top-offset-hover--small: 3px;
$border-width: 4px;
$border-width--small: 2px;
$tracker-height--small: 3px;
$transition-duration-handles: 50ms;

.block {
  height: $handle-size-hover + ($top-offset-hover * 2);
};

.tracker, .value {
  top: calc(50% - #{math.div($tracker-height, 2)});
  height: $tracker-height;
}

.handle {
  background: $handle-bg-color;
  border-color: $handle-border-color;
  border-width: $border-width;
  width: $handle-size !important;
  height: $handle-size !important;
  padding: 0;
  top: $top-offset !important;

  transition: all $transition-duration $transition-timing-function;
  will-change: height, width, top;
  transform: translateZ(0);

  &:hover, &:active, &:focus {
    outline: none;
    border-color: $handle-border-color;
    height: $handle-size-hover !important;
    width: $handle-size-hover !important;
    top: $top-offset-hover !important;
  }
}

.value {
  background: $value-color;
  display: flex;
  align-items: center;
  justify-content: center;
}

.hasVariant {
  &.small {
    height: $handle-size-hover--small + ($top-offset-hover--small * 2);

    & .track, & .value {
      top: calc(50% - #{math.div($tracker-height--small, 2)});
      height: $tracker-height--small;
    }

    & .handle {
      border-width: $border-width--small;
      top: $top-offset--small !important;
      border-color: $handle-border-color;
      width: $handle-size--small !important;
      height: $handle-size--small !important;
      padding: 0;

      transition: top $transition-duration-handles cubic-bezier(0.4, 0.0, 0.2, 1),
        width $transition-duration-handles cubic-bezier(0.4, 0.0, 0.2, 1),
        height $transition-duration-handles cubic-bezier(0.4, 0.0, 0.2, 1);

      &:hover, &:active, &:focus {
        outline: none;
        border-color: $handle-border-color;
        height: $handle-size-hover--small !important;
        width: $handle-size-hover--small !important;
        top: $top-offset-hover--small !important;
      }

      &:after {
        display: block;
        position: absolute;
        top: -6px;
        left: -6px;
        content: "";
        height: $handle-size-hover--small;
        width: $handle-size-hover--small;
        transition: top $transition-duration-handles cubic-bezier(0.4, 0.0, 0.2, 1),
          left $transition-duration-handles cubic-bezier(0.4, 0.0, 0.2, 1);
        border-radius: 50%;
      }

      &:hover:after, &:active:after, &:focus:after {
        cursor: pointer;
        top: -2px;
        left: -2px;
      }
    }
  }
}
