@import "ui-components/_variables.scss";

$padding: size("gutter--large");
$padding--small: 1.2rem;

.block {
  width: 100%;
  max-width: breakpoint("large");
  margin-left: auto;
  margin-right: auto;

  padding-left: $padding--small;
  padding-right: $padding--small;

  @media (min-width: breakpoint("small")) {
    padding-left: $padding;
    padding-right: $padding;
  }

  @media (min-width: breakpoint("large")) {
    padding-top: size("gutter--huge");
  }

  &__thin {
    max-width: breakpoint("tiny");
  }
  &__pageWrapper {
    @media (max-width: (breakpoint("large") - 1px)) {
      padding-top: size("header-margin");
    }
  }
}
