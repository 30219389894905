@use "sass:math";

@import "ui-components/_variables.scss";
@import "ui-components/_mixins.scss";
@import "../../../../node_modules/@crossroads/ui-components/src/ui-components/Input.scss";

$buttonBottomMargin: 1.8rem;
$spacerHeight: 26px;

.container {
  @media (min-width: breakpoint("small")) {
    display: flex;
    @include stretch(math.div(size("gutter"), 2));
    @include clearfix();
  }

  .removeBottomPadding {
    padding-bottom: 0px;
  }
}

.left + .right {
  margin-top: size("gutter");

  @media (min-width: breakpoint("small")) {
    margin-top: 0 !important;
  }
}

.left {
  @media (min-width: breakpoint("small")) {
    margin-right: math.div(size("gutter"), 2);
    float: left;
    flex: 0 1 40%;
  }
}

.right {
  @media (min-width: breakpoint("small")) {
    margin-left: math.div(size("gutter"), 2);
    float: right;
    flex: 1 0 60%;
  }

  @media (max-width: breakpoint("small") - 1px) {
    margin-top: size("gutter");
  }
}

.row {
  @media (min-width: breakpoint("small")) {
    @include stretch(math.div(size("gutter--small"), 4));
    @include clearfix();
  }
}

.s50 {
  @media (min-width: breakpoint("small")) {
    padding: 0 math.div(size("gutter--small"), 4);
    float: left;
    width: 50%;
  }
}

.boxHeader {
  padding: size("gutter");
}

.selector {
  width: 100%;
  margin-bottom: $buttonBottomMargin;
}

.button {
  width: 100%;
  height: 3.8rem !important;
  padding: 0px !important;
  margin-bottom: $buttonBottomMargin !important;
}

.spacer {
  height: $spacerHeight;

  @media (max-width: breakpoint("small") - 1px) {
    display: none;
  }
}

