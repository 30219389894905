@use "sass:math";

@import "ui-components/_variables.scss";
@import "ui-components/_mixins.scss";

$image-size: 80px;

.block {
  @media (min-width: breakpoint("medium")) {
    @include stretch(size("gutter"));
  }

  ol {
    list-style-type: decimal;
    list-style-position: inside;
    font-size: size("font--medium");
    font-weight: bold;

    h2 {
      display: inline-block;
      margin-bottom: size("gutter--small");
    }

    p {
      margin-top: 0;
      font-weight: normal;
      font-size: size("font");
    }
  }

  li + li {
    margin-top: size("gutter--large") * 1.2;
  }
}

.header {
  display: flex;
  align-items: center;

  margin-bottom: size("gutter--large") * 2;
}

.heading {
  margin-top: math.div(size("gutter--mini"), 2);
}

.title {
  font-size: size("font--large");
  margin-bottom: size("gutter--mini");
}

.orderNo {
  margin-top: 0;
  text-transform: uppercase;
  color: var(--textMuted);
  font-size: size("font--medium");
  letter-spacing: font("uc-letter-spacing");
}

.checkIcon {
  margin-right: size("gutter");
  height: 64px;
  width: 64px;
  flex-shrink: 0;
}

.steps {
  margin-bottom: size("gutter--large") * 2;
}

.customerService {
  margin-bottom: size("gutter--large") * 2;
}

.left {
  @media (min-width: breakpoint("medium")) {
    float: left;
    padding: 0 size("gutter");
    width: 50%;
  }
}

.right {
  @media (min-width: breakpoint("medium")) {
    float: right;
    padding: 0 size("gutter");
    width: 50%;
  }

  @media (max-width: (breakpoint("medium") - 1px)) {
    margin-bottom: size("gutter--large") * 2;
  }
}

.mapContainer {
  border-radius: var(--borderRadius);
  overflow: hidden;
  &__large {
    margin-bottom: size("gutter--large");
    @media (max-width: (breakpoint("medium") - 1px)) {
      //display: none;
    }
  }

  &__small {
    margin-bottom: size("gutter");
    @media (min-width: breakpoint("medium")) {
      display: none;
    }
  }
}

.map {
  background: #88aa88;

  height: 200px;
  @media (max-width: (breakpoint("medium") - 1px)) {
    height: 280px;
  }
}

.mapText {
  margin: 0;
  padding: size("gutter");
  color: var(--textMuted);
  background: var(--light);
}


.item {
  display: flex;
  justify-content: space-between;
  gap: size("gutter");

  background: var(--light);
  padding: size("gutter");
  font-size: size("font--small");
  border-radius: size("border-radius");
  transition: opacity transition("duration--atoms") transition("ease-in");

  & + & {
    margin-top: size("gutter--large");
  }

  .left,
  .right {
    display: flex;
    flex: 1 1 50%;
  }

  .left {
    justify-content: space-between;
    gap: size("gutter--mini");
  }

  .right {
    justify-content: center;
    margin: 0;

    @media (max-width: (breakpoint("small") - 1px)) {
      justify-content: left;
    }
  }

  @media (max-width: (breakpoint("medium") - 1px)) {
    padding-bottom: size("gutter") * 1.1;
  }

  @media (max-width: (breakpoint("tiny") - 1px)) {
    flex-direction: row-reverse;
  }
}

.contentWrapper {
  display: flex;
  width: 100%;

  @media (max-width: (breakpoint("small") - 1px)) { 
    flex-direction: column;
    gap: size("gutter--mini");
  };
}

.image {
  width: $image-size;
  height: $image-size;
  border-radius: math.div(size("border-radius"), 2);
  align-self: center;

  @media (min-width: (breakpoint("tiny"))) and (max-width: (breakpoint("small") - 1px)) {
    width: $image-size + 40px;
    height: $image-size + 40px;
  }

  &.mobileImg {
    display: none;
  }

  @media (max-width: (breakpoint("tiny") - 1px)) {
    display: none;

    &.mobileImg {
      display: block;
      align-self: flex-start;
    }
  }
}

.info {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: calc(#{size("grid-gutter")} / 2);

  @media (max-width: (breakpoint("medium") - 1px)) {
    position: static;
    @include clearfix();
  }
}

.name {
  color: var(--text);
  font-weight: bold;
  line-height: 1.2;
  margin: 0;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;  
  overflow: hidden;
}

.brand,
.qty {
  color: var(--textMuted);
  margin: 0;
}

.deliveryTime {
  display: flex;
  align-items: center;
  font-size: size("font--small");
  color: color("text--muted");

  @media (min-width: (breakpoint("tiny"))) {
    text-align: right;
  }
}

.price {
  font-size: size("font");
  font-weight: 700;
  display: flex;
  align-items: center;

  div > div {
    &:last-of-type {
      line-height: 1;
      font-size: size("font--small");
      color: color("text--muted");
      font-weight: normal;
    }
  }
}