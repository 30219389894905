@use "sass:math";

@import "../../node_modules/@crossroads/ui-components/src/ui-components/Tooltip.scss";
@import "ui-components/_variables.scss";

.tooltip {
  font-size: size("font--small");

  &Inner {
    box-shadow: none;
  }
}