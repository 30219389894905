@import "ui-components/_variables.scss";
@import "ui-components/_mixins.scss";


.rowCheckbox, .rowSelect {
    padding-bottom: size("gutter--large");
}

.fileInput {
    display: flex;
    flex-direction: column;
    padding-bottom: size("gutter--large");
  
    label {
      margin-bottom: size("gutter--mini");
    }
  
    input::file-selector-button {
      font-weight: 500;
      color: var(--light);
      background-color: var(--primary);
      border: 1px solid transparent;
      border-radius: 4px;
      cursor: pointer;
      padding: size("grid-gutter");
      transition: background 0.1s;
      
      &:hover {
        background: var(--primary-l1);
      }
    }
  }