@import "ui-components/_variables.scss";
@import "ui-components/_mixins.scss";


.link {
  margin-bottom: size("gutter--large");
  display: inline-block;
}

.content {
	display: grid;
	grid-template-columns: 1fr 1fr;
	gap: size("gutter--large");
	margin-bottom: size("gutter--huge");

	@media (max-width: (breakpoint("medium") - 1px)) {
    grid-template-columns: 1fr;
		gap: size("gutter--larger");
	}
}

.formList {
  display: flex;
  flex-direction: column;
  gap: size("gutter--medium");
}

.description {

    // margin-top: size("gutter--large");
    margin-bottom: size("gutter--large");
    border-radius: size("border-radius");

	& > div {
		padding: 0;
		background-color: color("disabled");
		border-radius: size("border-radius");
		overflow: hidden;

		h3, h4 {
			background-color: var(--primary-l2);
			padding: size("gutter");
			margin-bottom: 0;
		}
			
		&:not(:last-of-type) {
			margin-bottom: size("gutter--large");
		}

		& > div {
			padding: size("gutter");
			
			ul {
		    	list-style: inherit;
				line-height: 1.5;
				margin-left: size("gutter");
				
				li {
					padding-bottom: size("grid-gutter");
				}
			}
		}
	}
}