@import "ui-components/_variables.scss";
@import "ui-components/_mixins.scss";

$headerHeight: size("header-height");
$submitButtonHeight: 72px;
$closeButtonSideLength: 36px;
$closeButtonOffsetRight: -8px;

.header {
  flex: 0 0 auto;
  height: $headerHeight;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #fff;
  padding: size("gutter") size("gutter--large");
  flex-wrap: wrap;

  & > h2 {
    margin: 0;
    font-size: size("font--large");
    text-transform: none;
    font-weight: bold;
    color: var(--text);
  }

  .closeButton {
    width: $closeButtonSideLength;
    height: $closeButtonSideLength;
    right: $closeButtonOffsetRight;

    & > svg {
      width: 100%;
      height: 100%;
    }
  }
}

.activeFilters {
  border-top: 1px solid color("border--muted");
  border-bottom: 1px solid color("border--muted");

  //Container
  & > div {
    //ActiveFilters_activeFilters
    & > div {
      flex-direction: column;

      //Top and Bottom
      & > div {
        flex-direction: column;
        align-items: flex-start;
  
        //Top
        &:first-child {
           & > * {
             margin-right: 0;
             margin-bottom: size("gutter");

             &:last-child {
               @media (max-width: (breakpoint("tiny") - 1px)) {
                 margin-bottom: 0;
               }
             }
           }
        }
      }
  
      @media (max-width: (breakpoint("small") - 1px)) {
        margin-bottom: 0;
      }
    }

    @media (max-width: breakpoint("tiny")) {
      flex-direction: column;
      margin-bottom: 0;
    }
  }
}

.body {
  height: calc(100vh - #{$headerHeight});
  max-height: calc(100vh - #{$headerHeight});
  min-height: 0;

  .top {
    height: calc(100% - #{$submitButtonHeight});
    max-height: calc(100% - #{$submitButtonHeight});
  }

  .bottom {
    height: $submitButtonHeight;

    button.submit_button {
      width: 100%;
      height: 100%;
      border-radius: 0;
    }
  }
}

.filters {
  height: 100%;
  overflow-y: auto;
}

.filterMenu {
  border-top: none;
  width: 100%;
}
