@import "ui-components/_variables.scss";
@import "ui-components/_mixins.scss";


.wrapper {
  position: fixed;
  right: 0;
  top: 0;
  bottom: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;

}

.content {
  background-color: #fff;
  z-index: 1;
  padding: size("gutter--larger");
  color: var(--text);

  .noTransactions {
    width: 100%;
    margin: size("gutter--large") 0;
    text-align: center;

    h2 {
      font-style: italic;
      font-weight: 300;
      font-size: size("font--medium-small");
      padding: size("gutter--larger") 0;
    }
  }

  @media (max-width: breakpoint("small")) {
    padding: size("gutter--medium");
    height: 100vh;
    width: 100vw ;
    overflow: auto;
  }

  @media (min-width: breakpoint("medium")) {
    width: 75%;
  }
}

.header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  .title {
    font-size: size("font--large");
    margin: 0;

    .fullname {
      text-transform: capitalize;
      font-size: smaller;
    }
  }

  .closeBtn {
    min-width: fit-content;
  }
}

.bottom {
  p span {
    font-weight: bold;
  }
}

.overlay {
  background: rgba(0, 0, 0, .5);
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}