@import "ui-components/_variables.scss";
@import "ui-components/_mixins.scss";

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes slideDown {
  0% {
    opacity: .7;
    transform: translateY(-30px);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.container {
  height: size("header-height");

  @media (max-width: (breakpoint("small") - 1px)) {
    display: none;
  }
}

.block {
  z-index: z-index("overlay") + 1;
  height: 100%;

  flex: 1 0 100%;
  display: flex;
  justify-content: space-between;
  align-items: stretch;

  padding: 0 size("gutter--large");
}

.logo {
  flex: 0 0 auto;
}

.nav {
  display: flex;
  align-items: stretch;

  .helpdesk {

    display: flex;

    svg {
      margin-bottom: 0.2rem;
      max-height: size("gutter--medium");
      max-width: size("gutter--medium");
    } 
  }
}

.login {
  margin-left: size("gutter--large");
}

.item {
  font-size: size("font--medium-small");
  font-weight: 700;
  text-decoration: none;
  cursor: pointer;
  user-select: none;
  height: 100%;
  display: flex;
  align-items: center;

  &:hover:not(.item__customerBadge) {
    text-decoration: underline;
  }

  &.active:not(.item__customerBadge) {
    text-decoration: underline;
  }

  &__customerBadge {
    &:hover {
      text-decoration: none;

      p:first-child {
        text-decoration: underline;
      }
    }

    &.active {
      p:first-child {
        text-decoration: underline;
      }
    }
  }

  &+& {
    margin-left: size("gutter");
  }

  svg+span {
    margin-left: size("gutter--mini");
  }

  &__categories {
    color: var(--text);

    .chevron {
      transition: all transition("duration--organisms") transition("ease-ease");
      top: 0px;
      transform: rotate(90deg);
      margin-left: size("gutter--mini");
    }
  }

  @media (min-width: breakpoint("small")) and (max-width: (breakpoint("medium") - 1px)) {
    font-size: 14px;
  }
}

.item.active .chevron {
  transform: rotate(90deg) scaleX(-1);
}

.searchIcon {
  margin-right: size("gutter--mini");
  width: 22px;
  height: 22px;
}

.triangle {
  $triangle-size: .6em;
  @include triangle(top, $triangle-size, var(--light));

  left: 50%;
  margin-left: -$triangle-size;
  top: size("sub-header-height") + 2px;

  display: none;

  .item.active & {
    display: block;
  }
}

.brands {
  color: var(--text) !important;
}

.dim {
  position: fixed;
  top: size("header-height");
  bottom: 0;
  left: 0;
  right: 0;
  z-index: z-index("system");
  background: rgba(0, 0, 0, .3);

  animation: fadeIn transition("duration--organisms") transition("ease-out") forwards;
}

.expiresNotice {
  &:hover {
    text-decoration: none;

    >.notice {
      text-decoration: underline;
    }
  }
}

.exclamation {
  font-size: size("font--large");
}

.notice {
  font-size: size("font--mini");
  display: flex;
  flex-direction: column;
  margin-left: size("gutter--mini");

  .points {
    font-weight: 500;
  }
}

.districtName {
  margin-left: size("gutter--small");
  font-size: size("font--medium");
}

.adminBtnContainer {
  position: absolute;
  top: calc(#{size("header-height")} + #{size("gutter")});
  right: 0;

  button {
    padding: size("gutter--mini") size("gutter") !important;
    font-size: size("font--small") !important;
  }
}