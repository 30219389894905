@import "ui-components/_variables.scss";
@import "ui-components/_mixins.scss";

.usercell {
  color: var(--primary);

  &:hover {
    cursor: pointer;
    text-decoration: underline;
  }
}

.lightbox {
  padding: 0;
  width: 400px;

  &Header {
    display: flex;
    justify-content: space-between;
    align-items: center;

    padding: size("gutter--small") size("gutter");

    h2 {
      margin-bottom: 0;
      font-size: size("font--large");
    }

    svg {
      cursor: pointer;
    }
  }

  &Body {
    @include momentumScroll;
    overflow-y: auto;

    padding: size("gutter");
    padding-top: 0;
  }
}
