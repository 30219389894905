@use "sass:math";

@import "ui-components/_variables.scss";
@import "ui-components/_mixins.scss";

:export {
  tiny: strip-unit(breakpoint("tiny"));
  small: strip-unit(breakpoint("small"));
  medium: strip-unit(breakpoint("medium"));
  large: strip-unit(breakpoint("large"));
}

.popularCategoriesList {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  @include stretch(math.div(size("grid-gutter"), 2));
}

.popularCategoriesItem {
  display: flex;
  min-width: 0;
  padding: math.div(size("grid-gutter"), 2);
  flex: 0 0 math.div(100%, 6);

  @media (max-width: (breakpoint("medium") - 1px)) {
    flex: 0 0 math.div(100%, 3);
  }

  @media (max-width: (breakpoint("small") - 1px)) {
    flex: 0 0 math.div(100%, 3);
  }

  @media (max-width: (breakpoint("tiny") - 1px)) {
    flex: 0 0 math.div(100%, 2);
  }
}

.popularCategory {
  display: flex;
  flex-direction: column;
  width: 100%;
  color: var(--text);
  transition: transform transition("duration--atoms") transition("ease-in");
  &:hover {
    transform: translateY(-3px);

    h2 {
      text-decoration: underline;
    }
  }

  img {
    width: 100%;
    border-radius: var(--borderRadius);
  }

  h2 {
    margin-top: size("gutter--mini");
    text-align: center;
    font-size: size("font");
    color: currentColor;
  }
}
