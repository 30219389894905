@use "sass:math";

@import "ui-components/_variables.scss";

.block {
  text-transform: uppercase;
  font-size: size("font--small");
  margin-bottom: size("gutter");
}

.slash {
  margin: 0 0.4rem;
  color: var(--textMuted);
}

.links {
  line-height: font("line-height--breadcrumbs");
  color: var(--textMuted);
  overflow: hidden;
  text-overflow: ellipsis;


  > a,
  span:not(.product) {
    color: var(--text);
  }
}

.product {
  color: var(--textMuted);
}
