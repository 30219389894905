@use "sass:math";

@import "ui-components/_variables.scss";
@import "ui-components/_mixins.scss";

$footerHeight: 75px;

.block {
  // extra space in bottom to make up for bottom menu
  margin-bottom: size("gutter--large") * 3;

  @media (max-width: (breakpoint("medium") - 1px)) {
    margin-bottom: inherit;
  }
}

.body {
  padding-bottom: $footerHeight;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: size("gutter");
}

.link {
  font-weight: bold;
  font-size: 18px;
  color: var(--primary);
}

.title {
  display: flex;
  align-items: baseline;

  span {
    font-weight: bold;
    font-size: size("font--large");
    color: var(--textMuted);
  }

  @media (max-width: (breakpoint("medium") - 1px)) {
    h1, span {
      font-size: size("font--medium");
    }
  }
}

.heading, .headingEmpty {
  font-size: size("font--huge");
  margin: 0;
  margin-bottom: size("gutter");
  margin-right: size("gutter");

  @media (max-width: (breakpoint("medium") - 1px)) {
    font-size: size("font--large");
  }
}

.headingEmpty {
  margin: 0;
}

.footer {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  border: 1px solid var(--border);
  z-index: z-index("system");

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  background: var(--light);

  transition: transform transition("duration--organisms")
    transition("ease-ease");
}

.steps {
  flex: 1 0 auto;
  display: flex;

  @media (max-width: (breakpoint("medium") - 1px)) {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
  }
}

.checkmark {
  height: 24px;
  width: 24px;

  @media (max-width: (breakpoint("medium") - 1px)) {
    display: none;
  }
}

.step {
  flex: 1 0 auto;
  color: var(--textMuted) !important;
  margin: size("gutter--small");
  user-select: none;

  span {
    @media (min-width: breakpoint("medium")) {
      border-bottom: 2px solid var(--light);
    }
  }

  svg {
    margin-right: math.div(size("gutter--small"), 2);
  }

  @media (max-width: (breakpoint("medium") - 1px)) {
    margin: 0;
    padding: size("gutter--small");
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;

    &:not(:first-child) {
      border-left: 1px solid var(--border);
    }
  }

  @media (max-width: breakpoint("tiny")) {
    padding: size("gutter--small") size("gutter--mini");
    font-size: size("font--small");
  }

  @media (min-width: (breakpoint("medium") - 1px)) {
    & + & {
      margin-left: size("gutter--small");

      @media (max-width: (breakpoint("tiny") - 1px)) {
        margin-left: size("gutter--mini");
      }
    }
  }

  &:hover {
    text-decoration: none;
  }

  &.active:not(.disabled) {
    color: var(--primary) !important;

    span {
      border-color: var(--primary);
    }
  }

  &.completed:not(.disabled) {
    color: var(--text) !important;

    svg {
      fill: var(--positive);
    }
  }

  &.disabled {
    cursor: not-allowed;
    color: color("text--muted") !important;

    svg {
      fill: var(--textMuted);
    }

    &:hover {
      text-decoration: none;
    }

    &:active {
      opacity: 1 !important;
    }
  }
}

.empty {
  flex: 1 0 auto;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  header {
    margin-bottom: size("gutter--large") * 2;
  }
}
