
@import "ui-components/_variables.scss";
@import "ui-components/_mixins.scss";


div.affiliateList {
  justify-content: flex-start;
  margin: -.25rem;
}

.item {
  @media (max-width: (breakpoint("mini") - 1px)) {
    flex: 0 0 100%;
  }
}
