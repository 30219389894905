@use "sass:math";

@import "ui-components/_variables.scss";
@import "ui-components/_mixins.scss";

$bannerHeight: 450px;
$bannerHeight--small: 300px;

.section {
  &:first-child {
    margin-top: math.div(size("gutter--module"), 2);
  }
}

.cta {
  font-size: size("font--medium");
  align-self: center;
  margin: size("gutter") 0 size("gutter");
  text-transform: uppercase;

  @media (max-width: (breakpoint("tiny") - 1px)) {
    font-size: size("font") !important;
  }
}

.items {
  display: flex;
  flex-wrap: wrap;

  @media (max-width: (breakpoint("medium") - 1px)) {
    justify-content: center;
  }

  .item {
    flex: 1 0 23%;
    margin: math.div(size("grid-gutter"), 2);

    @media (max-width: (breakpoint("medium") - 1px)) {
      flex: 1 0 32%;
    }

    @media (max-width: (breakpoint("tiny") - 1px)) {
      flex: 1 0 100%;
    }
  }
}

.popularProducts {
  margin-top: size("gutter--large");
  display: flex;
  justify-content: center;
}
