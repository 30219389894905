@import "ui-components/_variables.scss";
@import "ui-components/_mixins.scss";

.block {
  transition: opacity transition("duration--atoms") transition("ease-in");

  &.processing {
    opacity: 0.6;
    pointer-events: none;
  }
}

.closeIcon {
  transition: transform transition("duration--organisms") transition("ease-in");

  transform: rotate(-90deg) scaleX(1);
  .open & {
    transform: rotate(-90deg) scaleX(-1);
  }
}

.body {
  padding-top: 0;

  table {
    width: 100%;
    line-height: font("line-height");
  }

  td {
    vertical-align: top;

    &:last-child {
      text-align: right;
    }
  }

  hr {
    border-color: color("disabled");
    margin: size("gutter") 0;
  }

  button {
    margin: size("gutter--large") 0;
    margin-bottom: 0;
    width: 100%;
  }

  @media (max-width: (breakpoint("medium") - 1px)) {
  }
}

.tableSection {
  padding-top: size("gutter--small");
}

.footer {
  padding-top: size("gutter--small");
  color: var(--textMuted);
}

.separatorFull {
  height: 1px;
  background-color: color("disabled");
  margin-left: -#{size("gutter")};
  margin-right: -#{size("gutter")};
  margin-top: size("gutter--small");
}

.separator {
  background-color: color("disabled");
  height: 1px;
  width: 100%;
  margin: size("gutter") 0;
}

.split {
  display: flex;
  flex-direction: column;

  span {
    &:nth-child(2){
      line-height: 1.5;
      color: color("text--muted");
      font-size: size("font--small");
    }
  }

}
