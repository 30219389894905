@import "ui-components/_variables.scss";

:export {
  small: strip-unit(breakpoint(small));
}

.block {
  background: var(--background);
  color: var(--invertedText);
  margin-top: size("gutter--module");
  content-visibility: auto;
}

.wrapper {
  display: flex;
  flex-direction: column;
  padding-top: size("gutter--huge");
  padding-bottom: size("gutter--huge");

  @media (max-width: (breakpoint("small") - 1px)) {
    flex-wrap: wrap;
  }
}

.section, .transaction {
  display: flex;
  margin: size("gutter--module") 0;

  tbody {
    border-radius: 4px;
    background: color("background");
    color: var(--text);

    tr:first-child {
      td {
        border: 0;
      }
      td:first-child {
        border-radius: 4px 0 0 0;
      }
      td:last-child {
        border-radius: 0 4px 0 0;
      }
    }

    tr:last-child {
      td {
        padding-bottom: size("gutter--small");
      }
      td:first-child {
        border-radius: 0 0 0 4px;
      }
      td:last-child {
        border-radius: 0 0 4px 0;
      }
    }
  }

  .left, .right {
    width: 50%;

    .title {
      margin: 0 0 size("grid-gutter") * 3;
    }

    .description {
      margin: 0 0 size("paragraph-margin");
    }
  }

  .left {
    margin-right: size("gutter--large");
  }

  .right {
    margin-left: size("gutter--large");
  }

  @media (max-width: (breakpoint("medium") - 1px)) {
    flex-direction: column;
    margin: size("gutter--huge") 0;

    .left, .right {
      width: 100%;
      margin: initial;
      max-width: 650px;
    }

    .left {
      margin-bottom: size("gutter--huge");
    }
  }

  &__bottom {
    display: flex;
    justify-content: center;
  }

  .link {
    color: var(--invertedText);
  }
}

.transaction {
  align-items: center;

  .left, .right {
    .title {
      font-size: size("font--huge");
    }
  }

  @media (max-width: (breakpoint("medium") - 1px)) {
    .right {
      max-width: initial;
    }
  }

  @media (max-width: (breakpoint("small") - 1px)) {
    .left, .right {
      .title {
        font-size: size("font--large");
      }
    }
  }

  .link {
    font-size: size("font--medium-small");
  }
}

.centeredCta {
  text-decoration: underline;
}

.copyWrapper {
  padding-top: size("gutter--large");
  padding-bottom: size("gutter--large");

  .row {
    flex: 1 0 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  @media (max-width: (breakpoint("mini") - 1px)) {
    svg {
      width: 80px;
      margin-bottom: size("gutter--small");
    }

    .row {
      display: block;
      padding-bottom: size("gutter--mini");
    }
  }
}

.divider {
  background-color: var(--light);
  height: 1px;
  opacity: .4;
}

.copyright {
  a {
    text-decoration: underline;
  }
}

.categories {
  margin: size("gutter--module") 0;

  h1 {
    margin-bottom: size("gutter--huge");
  }
}

.links {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: repeat(4, 1fr);
  grid-column-gap: size("gutter--large");
  grid-row-gap: size("gutter--huge");

  @media (max-width: (breakpoint("tiny") - 1px)) {
    grid-template-columns: repeat(3, 1fr);
  }

  @media (max-width: (breakpoint("mini") - 1px)) {
    grid-template-columns: repeat(2, 1fr);
  }
}

.link {
  color: var(--light) !important;
}

.footerLinks {
  li + li {
    margin-top: size("gutter--mini");
  }
}
