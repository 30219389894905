@use "sass:math";

@import "ui-components/_variables.scss";
@import "ui-components/_mixins.scss";

$priceMarginTop: 0.8rem;
$button-size: 32px;

:export {
  mini: strip-unit(breakpoint("mini"));
  small: strip-unit(breakpoint("small"));
  medium: strip-unit(breakpoint("medium"));
  large: strip-unit(breakpoint("large"));
}

.block {
  padding: size("gutter--huge") 0rem;
}

.blockMultiple {
  padding: size("gutter--huge") size("gutter--small");
}

iframe {
  max-width: 100%;
}

.wrapper {
  height: 100%;
  max-width: breakpoint("small");
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  background: #fff;
  padding: size("gutter--large");
  border-radius: var(--borderRadius);
  line-height: font("line-height--medium-small");

  @media (max-width: (breakpoint("medium") - 1px)) {
    width: 100%;
    padding: size("gutter--small");

    form button {
      width: 100%;
    }
  }

  img {
    max-width: 300px !important;
  }
}

.answers {
  margin: size("gutter--medium") 0rem size("gutter--huge");
}

.errorIcon {
  color: var(--negative);
}

.successIcon {
  color: var(--positive);
}

.prev {
  svg {
    transform: rotate(-180deg);
  }
}

.title {
  text-align: center;
  margin-top: size("gutter--mini");
}

.subTitle {
  &Closed {
    color: var(--negative);
  }
}

.description {
  margin-bottom: size("gutter--large");
}

.questions {
  display: flex;
  flex-direction: column;
  margin-top: size("gutter--large");

  .listoption {
    display: block;
    width: 100%;
    text-align: left;
    border: 1px solid var(--borderMuted);
    padding: size("gutter--small") size("gutter") !important;

    &:not(:first-child) {
      margin-top: math.div(size("gutter--mini"), 2);
    }

    &__active {
      background: var(--primary);
      border: 1px solid var(--primary);
      color: var(--light);

      &:hover {
        background: var(--primary-l1);
        border: 1px solid var(--primary-l1);
      }
    }
  }

  .next {
    display: flex;
    justify-content: flex-end;

    &Button {
      margin-top: size("gutter--large") !important;

      @media (max-width: (breakpoint("mini") - 1px)) {
        width: 100%;
      }
    }
  }
}

.radio, .checkbox {
  pointer-events: none;
  display: flex;
  align-items: flex-start;
  width: 100%;
  padding: size("gutter--mini") 0;

  & + & {
    margin-top: math.div(size("gutter--mini"), 2);
  }

  label {
    width: 100%;
    &::before {
      flex-shrink: 0;
    }
  }

  span {
    width: 100%;
    display: flex;
    justify-content: space-between;
  }
}

.checkbox label {
  align-items: initial;
}

.imageInput {
  cursor: pointer;
  background-color: #eee;
  border: 1px solid var(--border);
  border-radius: var(--borderRadius);
  width: 400px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: size("gutter--small");

  input {
    display: none;
  }

  button {
    font-size: size("font--small") !important;
    padding: size("gutter--mini") size("gutter--mini")!important;
    pointer-events: none;
  }
}

.success {
  @media (max-width: (breakpoint("small") - 1px)) {
    &__right {
      margin-bottom: size("gutter--large");
    }
  }
  @media (min-width: breakpoint("small")) {
    @include stretch(size("gutter--small"));

    &__left {
      float: left;
      width: 50%;
      padding: 0 size("gutter--small");
    }

    &__right {
      float: right;
      width: 50%;
      padding: 0 size("gutter--small");
      img {
        width: 100%;
      }
    }
  }

  .backButton {
    margin-top: size("gutter");
  }
}

.nextButton {
  align-self: flex-end;
}

.textAreaHistory {
  background: #eee !important;
  border: none !important;
  color: #7d726c !important;
}

.checkboxCorrect {
  label {
    width: 100%;
    &::before {
      flex-shrink: 0;
    }
  }

  span {
    width: 100%;
    display: flex;
    justify-content: space-between;
    color: #87d180;
    font-weight: bold;
  }
}

.radioCorrect {
  color: #87d180;
  font-weight: bold;
}

.correctInput {
  color: #87d180;
  font-weight: bold;
}

.inputHistory {
  padding-bottom: 0;
}

.radio {
  p {
    margin-top: 0;
    margin-left: auto;
  }
}

.quizHeader {
  display: flex;
  justify-content: space-between;
  margin-bottom: size("gutter--medium");

  h2 {
    margin-bottom: 0;
  }
}

.quizQuestion {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;

  p {
    white-space: nowrap;
    padding-left: size("gutter--small")
  }
}