@import "ui-components/_variables.scss";
@import "ui-components/_mixins.scss";

$tableMinHeight: 200px;

@keyframes shine {
  0% {
    background-position: -100%;
  }
  100% {
    background-position: 100%;
  }
}

.tableLarge {
  width: 100%;
  margin: size("gutter--large") 0;
  min-height: $tableMinHeight;
  display: flex;
  flex-direction: column;

  @media (max-width: (breakpoint("small") - 1px)) {
    display: none;
  }

  table {
    table-layout: fixed;
  }

  tbody {
    border: 1px solid var(--border);
    border-radius: var(--borderRadius);
  }

  thead th {
    padding: size("gutter--small");
    font-weight: normal;
    font-size: size("font--small");
    text-align: left;
  }


  tr td {
    padding: size("gutter--small");
  }

  tr td {
    border-top: 1px solid var(--border);
  }

  .negative {
    color: var(--negative);
  }

  .receiptLink {
    display: flex;
    align-items: center;
    color: var(--primary);

    &:hover, &:focus {
      color: var(--primary-l1);

      span {
        text-decoration: underline;
      }
    }

    svg {
      width: 14px;
      height: 14px;
      margin-right: size("gutter--mini");
      top: 1px;
    }
  }
}

.tableHintLarge {
  margin-top: size("gutter--large");
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: $tableMinHeight;

  @media (max-width: (breakpoint("small") - 1px)) {
    display: none;
  }
}
