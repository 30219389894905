@import "ui-components/_variables.scss";
@import "ui-components/_mixins.scss";

.box {
  margin-top: size("gutter--large");
}

.support {
  padding: size("gutter--medium");
  padding-bottom: size("gutter--medium") * 1.6;
  display: flex;
  flex-direction: column;
  align-items: center;

  > * {
    margin: size("grid-gutter") 0;
  }

  .link {
    font-size: size("font--medium-small");
    color: var(--primary);
  }

  span {
    font-weight: bold;
    font-size: 16px;
  }
}
