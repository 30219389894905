@import "ui-components/_variables.scss";
@import "ui-components/_mixins.scss";

$tableMinHeight: 200px;

@keyframes shine {
  0% {
    background-position: -100%;
  }

  100% {
    background-position: 100%;
  }
}

.tableSmall {
  width: 100%;
  min-height: $tableMinHeight;
  display: flex;
  flex-direction: column;

  @media (min-width: breakpoint("small")) {
    display: none;
  }

  table {
    margin: 0;
  }

  tbody,
  .tbody {
    border: 1px solid var(--border);
    border-radius: var(--borderRadius);
  }

  thead th,
  .th {
    padding: size("gutter--small");
    font-weight: normal;
    font-size: size("font--small");
    text-align: left;
  }

  .tr {
    display: flex;
    cursor: pointer;
    user-select: none;
    transition: opacity transition("duration--atoms") transition("ease-in");

    &:hover {
      opacity: .6;
      text-decoration: underline;
    }
  }

  .tr>*{
    flex: 1 0 auto;

    &:nth-child(1) {
      flex-basis: 50px;
      flex-grow: 1;
    }
  }

  .chevron {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    flex: 1 0 10%;
    padding: 0 size("gutter--small") 0 0 !important;
    width: 50px;

    svg {
      transform: rotate(90deg);
      transition: transform transition("duration--atoms") transition("ease-in");
    }
  }

  .tr.open .chevron svg {
    transform: rotate(-90deg);
  }


  tr td,
  .tr>div {
    padding: size("gutter--small");
  }

  tr td,
  .tr:not(:first-child)>div {
    border-top: 1px solid var(--border);
  }
}

.expandable {
  border-top: 1px solid var(--border);

  table {
    width: 100%;
  }

  tbody {
    border: none;
  }

  td {
    border: none !important;
    width: 50%;
  }

  tr {
    border: none !important;
  }
}

.tableHintSmall {
  margin-top: size("gutter--large");
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: $tableMinHeight;

  @media (min-width: breakpoint("small")) {
    display: none;
  }
}
