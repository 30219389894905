@import "ui-components/_variables.scss";
@import "ui-components/_mixins.scss";

.wrapper {
  line-height: font("line-height");

  h1 {
    font-size: size("font--huge");
  }

  ol, ul {
    line-height: font("line-height");
  }
}

.loginWrapper {
  background-color: #fff;
  color: #000;
  margin-bottom: size("gutter");
  margin-top: size("gutter");
}

.header {
  margin-bottom: size("gutter--large") !important;

  a {
    display: inline-block;
  }

  .link {
    svg {
      transform: rotate(180deg);
      color: #000;
    }
  }
};