@import "ui-components/_variables.scss";
@import "ui-components/_mixins.scss";

@keyframes shine {
  0% {
    background-position: -100%;
  }
  100% {
    background-position: 100%;
  }
}

.block {
  color: color("text--muted");

  .provider {
    display: flex;
    background: #f8fafc;
    padding: size("gutter--mini");
    border-radius: var(--borderRadius);

    &:not(:first-child) {
      margin-top: size("gutter--mini");
    }

    @media (max-width: (breakpoint("tiny") - 1px)) {
      flex-direction: column;
    }

    .info {
      flex: 1 1 auto;
      display: flex;
      align-items: center;

      & > div + div {
        margin-left: size("gutter--large");
      }

      .date, .status {
        white-space: nowrap;
      }

      .name {
        flex: 1 1 auto;
      }

      .status {
        color: var(--positive);
        flex: 0 0 110px;
        text-align: end;

        &Inactive {
          color: var(--negative);
        }
      }

      @media (max-width: (breakpoint("small") - 1px)) {
        .date {
          display: none !important;
        }

        & > div + div {
          margin-left: size("gutter");
        }
      }

      @media (max-width: (breakpoint("tiny") - 1px)) {
        flex-direction: column;
        align-items: flex-start;

        .name {
          flex: 0 1 auto;
        }

        & > div + div {
          margin-left: 0;
          margin-top: size("gutter--small");
        }

        .status {
          text-align: start;
          flex-basis: auto;
        }
      }
    }

    .actions {
      flex: 0 0 auto;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      margin-top: size("gutter");

      & > button {
        padding: size("gutter--mini") size("gutter--large");
      }

      & > * + * {
        margin-left: size("gutter");
      }

      @media (min-width: breakpoint("tiny")) {
        margin-top: 0;
        margin-left: size("gutter");
      }
    }

    .hint {
      background-image: linear-gradient(90deg, color("disabled") 0%, color("background") 50%, color("disabled") 100%);
      background-size: 200%;
      animation: shine 1.5s infinite linear;
      height: 4rem;
    }
  }
}
