@use "sass:math";

@import "ui-components/_variables.scss";
@import "ui-components/_mixins.scss";

@keyframes shine {
  0% {
    background-position: -100%;
  }
  100% {
    background-position: 100%;
  }
}

.block {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  border: 1px solid var(--border);
  border-radius: var(--borderRadius);
  padding: size("gutter--medium");
  color: var(--text);
  background: #fff;

  @media (max-width: (breakpoint("medium") - 1px)) {
    padding: size("gutter");
  }

  &:hover {
    text-decoration: none;
  }

  &.open:hover .title {
    text-decoration: underline;
  }

  .title {
    font-size: size("font--large");
    margin-top: size("gutter--mini");
    font-family: var(--fontBody),
    var(--fontBodyFallback, #{font("fallback")});
    color: color(--text);
  }

  .top {
    .status {
      margin-top: 0;
      margin-bottom: 0;
      line-height: 1;

      &__open {
        color: var(--positive);
      }
      &__closed {
        color: var(--negative);
      }
    }
  }

  .bottom {
    color: color("text--muted");
    margin-top: size("gutter--small");

    p {
      margin: 0;
      line-height: 1;
    }

    p + p {
      margin-top: #{math.div(size("gutter--mini"), 2)};
    }
  }

  img {
    border-radius: var(--borderRadius);
    width: 100%;
    margin-bottom: size("gutter--small");
  }
}

.answered {
  opacity: 0.9;
  cursor: not-allowed;
}

.shadow {
  opacity: 0;
  transition: opacity transition("duration--atoms") transition("ease-in");
  box-shadow: 0 12px 32px 0 rgba(0, 20, 31, 0.12);
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  .block.open:hover & {
    opacity: 1;
  }
}

.blockAnswered {
  cursor: not-allowed;
}

.dummy {
  &_block {
    flex: 1;
    width: 100%;
    border: 1px solid var(--border);
    border-radius: var(--borderRadius);
    padding: size("gutter--large");

    @media (max-width: (breakpoint("medium") - 1px)) {
      padding: size("gutter");
    }
  }

  &_top {
    display: flex;
    justify-content: flex-end;
  }

  &_date {
    height: size("font");
    width: 50px;
    max-width: 50px;
    background-image: linear-gradient(90deg, color("disabled") 0%, color("background") 50%, color("disabled") 100%);
    background-size: 100px;
    animation: shine 1.5s infinite linear;
  }

  &_title {
    margin-top: size("gutter");
    margin-bottom: size("gutter");
    height: size("font--large");
    width: 100%;
    max-width: 100%;
    background-image: linear-gradient(90deg, color("disabled") 0%, color("background") 50%, color("disabled") 100%);
    background-size: 200%;
    animation: shine 1.5s infinite linear;
  }
}

.bottomLink {
  margin-top: size("gutter--mini");
}
