@import "ui-components/_variables.scss";
@import "ui-components/_mixins.scss";

@keyframes fadeIn {
  0% { opacity: 0; }
  100% { opacity: var(--overlayOpacity, 1); }
}

.modal {
  position: fixed;
  top: size("header-height");
  right: 0;
  bottom: 0;
  left: 0;
  z-index: #{z-index("system") - 1};

  @media (min-width: breakpoint("tiny")) {
    top: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: calc(#{size("header-height")} + #{size("gutter")});
    padding-bottom: size("gutter");
  }

  .overlay {
    position: absolute;
    background: var(--overlayColor);
    animation: fadeIn transition("duration--organisms") transition("ease-out") forwards;
    top: calc(#{size("header-height")} + #{size("sub-header-height")});
    bottom: 0;
    left: 0;
    right: 0;

    @media (max-width: (breakpoint("tiny") - 1px)) {
      display: none;
    }
  }

  &.subMenuHidden .overlay {
    top: size("header-height");
  }

  .container {
    padding: size("gutter");
    padding-top: size("gutter--larger") * 2;
    padding-bottom: size("gutter--larger") * 2;
    border-radius: var(--borderRadius);
    box-shadow: 0 8px 15px 0 rgba(0, 0, 0, 0.1);
    background-color: #fff;
    max-height: 100%;
    min-height: 100%;
    width: 100%;
    overflow-y: auto;

    @media (min-width: breakpoint("tiny")) {
      min-height: auto;
    }

    @media (min-width: breakpoint("small")) {
      width: auto;
      min-width: 800px;
    }

    header {
      max-width: 580px;
      margin: auto;

      h1 {
        text-align: center;
        font-size: size("font--large");
        margin: 0;
      }

      p {
        text-align: center;
        font-size: size("font--small");
        color: var(--textMuted);
      }

    }
    .closeButton {
      position: absolute;
      right: 0.5rem;
      top: 0.5rem;
      z-index: 1;
      color: var(--primary, #000);
    }
  }

  .body {
    margin: auto;
    margin-top: size("gutter--large") * 2;
  }
}

