@use "sass:math";

@import "ui-components/_variables.scss";
@import "ui-components/_mixins.scss";

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.wrapper {
  padding-top: size("gutter--huge");
}

.description {
  font-size: size("font--medium-small");
  color: var(--textMuted);
  margin-bottom: size("gutter--huge");
  max-width: 45rem;

  &.column {
    column-count: 2;
    max-width: initial;
  }
}

.items {
  @media (min-width: breakpoint("small")) {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    @include stretch(math.div(size("gutter"), 2));
  }
}

.item {
  animation: fadeIn transition("duration--organisms") transition("ease-ease") forwards;
  opacity: 0;

  @media (min-width: breakpoint("small")) {
    display: flex;
    align-items: stretch;
    flex: 0 0 50%;
  }

  @media (min-width: breakpoint("medium")) {
    flex: 0 0 33.33%;
  }

  @media (min-width: breakpoint("small")) {
    padding: 0 math.div(size("gutter"), 2);
  }

  padding-bottom: size("gutter") !important;

  .count__1 & {
    flex: 1 0 100%;
  }
}

.no_items {
  padding-bottom: size("gutter--large");

  @media (min-width: breakpoint("small")) {
    padding: 0 math.div(size("gutter"), 2);
    padding-bottom: size("gutter--large");
  }
}
