@use "sass:math";
@import "ui-components/_variables.scss";

.wrapper {
  .heading {
    font-weight: 700;
    font-size: size("font--huge");
    color: var(--primary);
  }

  .content {
    min-height: 50vh;
    color: var(--text);
    background-color: var(--light);
    font-size: size("font--medium-small");
    border-radius: size("border-radius");
    padding: size("gutter--larger");

    @media (max-width: (breakpoint("tiny") - 1px)) {
      padding: size("gutter--larger") size("gutter");
    }
  }

  .link {
    font-weight: 700;
  }
}
