@import "ui-components/_variables.scss";
@import "ui-components/_mixins.scss";

@keyframes shine {
  0% {
    background-position: -100%;
  }
  100% {
    background-position: 100%;
  }
}

.table {
  margin-top: size("gutter--large");
  width: 100%;

  &__small {
    @media (min-width: breakpoint("small")) {
      display: none;
    }
  }

  &__large {
    @media (max-width: (breakpoint("small") - 1px)) {
      display: none;
    }
  }

  tbody, .tbody {
    border: 1px solid var(--border);
    border-radius: var(--borderRadius);
  }

  thead th, .th {
    padding: size("gutter--small");
    font-weight: normal;
    font-size: size("font--small");
    text-align: left;
  }

  .thead {
    display: flex;
  }

  .tr {
    display: flex;
    cursor: pointer;
    user-select: none;
    transition: opacity transition("duration--atoms") transition("ease-in");

    &:hover {
      opacity: .6;
      text-decoration: underline;
    }
  }

  .tr > *, .thead > * {
    flex: 1 0 45%;
  }

  tr td, .tr > div {
    padding: size("gutter--small");
  }

  tr td, .tr:not(:first-child) > div {
    border-top: 1px solid var(--border);
  }

  .negative {
    color: var(--negative);
  }
}

.dummy {
  tbody td, .tr > div {
    background-image: linear-gradient(90deg, color("disabled") 0%, color("background") 50%, color("disabled") 100%);
    background-size: 200%;
    animation: shine 1.5s infinite linear;
    height: size("font");
  }
}
