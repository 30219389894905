@import "ui-components/_variables.scss";

:export {
  mini: strip-unit(breakpoint("mini"));
  small: strip-unit(breakpoint("small"));
  medium: strip-unit(breakpoint("medium"));
  large: strip-unit(breakpoint("large"));
}

.promotion {
  display: flex;
  background-color: var(--secondary);
  color: var(--invertedText);
  content-visibility: auto;

  .left,
  .right {
    width: 50%;
  }

  .left {
    margin-bottom: 0;
    margin-right: size("gutter--large");
  }

  .right {
    margin-left: size("gutter--large");
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-around;
    padding: size("gutter--large");
  }

  @media (max-width: (breakpoint("medium") - 1px)) {
    flex-direction: column;

    .left,
    .right {
      width: 100%;
      margin: initial;
    }

    .left {
      &:not(.empty) {
        margin-bottom: size("gutter");
      }
    }
  }
}

.section {
  display: flex;
  flex-direction: column;
  justify-content: center;
  content-visibility: auto;

  .title,
  .description {
    max-width: 650px;
  }
}

.cta {
  font-size: size("font--medium");
  text-transform: uppercase;
  border: 2px solid var(--primary);
  border-radius: var(--borderRadius);
  padding: size("gutter--small") size("gutter--medium");
  font-weight: 800;

  &Large {
    font-size: size("font--large");
  }
}

.top {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: size("gutter");

  @media (max-width: breakpoint("tiny")) {
    flex-direction: column;
    margin-bottom: size("gutter--large");
  }
}

.featuredProducts,
.earnOnline {
  display: flex;
  justify-content: center;
}

@media (max-width: (breakpoint("tiny") - 1px)) {

  .cta,
  .ctaLarge {
    font-size: size("font") !important;
  }
}

.wrapper {
  margin-top: size("gutter--section");

  @media (max-width: (breakpoint("medium") - 1px)) {
    margin-top: size("gutter--huge");
  }

  @media (max-width: (breakpoint("tiny") - 1px)) {
    margin-top: size("gutter--huge") 0;
  }

  &>*:not(:first-child) {
    margin-top: size("gutter--section");
  }
}

.uploadImageSection {
  content-visibility: visible;

  .title {
    margin: 0;
    flex-shrink: 1;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .top {
    margin: 0 0 size("gutter--large") 0;
  }
}

.uploadImage {
  display: flex;

  .btn {
    padding: size("gutter");
    text-transform: none;
  }

  .ctaLink {
    display: flex;
    align-items: center;
    text-align: end;
    border-radius: var(--borderRadius);
    padding: size("gutter--small") size("gutter--medium");
  }

  @media (max-width: (breakpoint("tiny") - 1px)) {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: size("gutter--medium");
  }
}

.uploadedImageContainer {
  @media (max-width: (breakpoint("mini") + 20px)) {
    flex: 0 0 100%;
  }

  .innerImageContainer {
    height: 290px;
    overflow: hidden;

    img {
      object-fit: cover;
      height: 100%;
      width: 100%;
    }
  }

  .imageContainerTitle {
    max-width: 90%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
}

.imageViewDesc {
  margin-bottom: size("gutter--larger");
}

.currenInfoCardsSection {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: size("gutter");

  @media (min-width: breakpoint("medium")) {
    grid-template-columns: 1fr 1fr 1fr;
  }
}

.currenInfoCardImage {
  display: flex;
  justify-content: center;
  margin: size("grid-gutter") 0;
}

.currenInfoCardsSectionCardWrapper {
  display: flex;
  flex-wrap: wrap;
  flex: 1 1 auto;
  margin: 0;

  @media (max-width: (breakpoint("medium") - 1px)) {
    flex: 0 0 100%;
  }
}

.currentInfoCard {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: var(--light);
  color: var(--text);
  box-shadow: 5px 5px 15px 1px rgba(0, 0, 0, 0.1);
  flex-grow: 1;
  transition: transition("duration--atoms") transition("ease-in");
  padding: size("gutter--large");

  @media (max-width: (breakpoint("small") - 1px)) {
    padding: size("gutter");
  }

  img {
    width: 100%;
    margin-bottom: size("grid-gutter");
  }

  h3 {
    margin-bottom: size("grid-gutter");
  }

  p {
    margin-top: 0;
  }

  &Content {
    padding: size("gutter");
  }
}

.currentInfoDescription {
  font-size: size("font--small");
  max-width: 600px;
  display: flex;
  flex-direction: column;

  p:last-child {
    justify-self: end;
  }

  a {
    display: table;
    background-color: var(--primary);
    color: var(--light);
    padding: size("gutter--mini") * 1.55 size("gutter") size("gutter--mini") * 1.6;
    margin-top: size("gutter");
    border-radius: var(--borderRadius);
  }
}