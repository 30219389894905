@import "ui-components/_variables.scss";
@import "ui-components/_mixins.scss";

.heroSection {
  position: relative;
  z-index: 1;

  @media (max-width: breakpoint("large")) {
    margin-bottom: size("gutter--huge");
  }
}

.heroWrapper[class] {
  text-align: center;
  padding-top: size("gutter--huge");
  padding-bottom: size("gutter--section");

  @media (min-width: breakpoint("large")) {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-height: 300px;
  }
}

.heroTitle {
  position: relative;
  z-index: 2;
  font-size: size("font--enormous");
  hyphens: auto;
  margin: 0 auto size("gutter--large");

  @media (max-width: breakpoint("small")) {
    font-size: size("font--huge");
  }
}

.heroDescription {
  font-size: size("font--large");

  @media (max-width: breakpoint("small")) {
    font-size: size("font--medium");
  }
}
  
.heroBGImage {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  height: 100%;
  width: 100%;
  object-fit: cover;
  object-position: center;
}

.heroWrapperAhlsellAnniversary {
  padding-bottom: calc(size("gutter--huge") * 3) !important;

  @media (min-width: breakpoint("small")) {
    padding-bottom: calc(size("gutter--huge") * 4) !important;
  }
}
