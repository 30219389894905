@use "sass:math";

@import "ui-components/_variables.scss";
@import "ui-components/_mixins.scss";

$input-file-height: 50px;
$blockPaddingBottom: 1.8rem;

.wrapper {
  background: color("disabled");
	margin-top: size("gutter--large");
  margin-bottom: size("gutter--large");
  border-radius: size("border-radius");
  padding-top: size("gutter--large");
  padding-bottom: size("gutter--large");
  height: fit-content;

	@media (max-width: (breakpoint("medium") - 1px)) {
		width: 100%;
	}
}

.textField, .fileInput {
  margin-bottom: size("gutter");
}

.fileInput {
  display: flex;
  flex-direction: column;
  padding-bottom: size("gutter--large");

  label {
    margin-bottom: size("gutter--mini");
  }

  input::file-selector-button {
    font-weight: 500;
    color: var(--light);
    background-color: var(--primary);
    border: 1px solid transparent;
    border-radius: 4px;
    cursor: pointer;
    padding: size("grid-gutter");
    transition: background 0.1s;
    
    &:hover {
      background: var(--primary-l1);
    }
  }
}

.checkbox {
  margin-bottom: size("gutter--huge");

  label {
    &::before{
      background-color: white;
    }

    &:hover{
      text-decoration: none !important;
    }
  }
}

.dropdownField {

  .dropdown {
    margin-bottom: size("gutter--larger");
    border-radius: var(--bordeRadius);
    width: 100%;

    & * {
      background-color: #fff ;
    }

    &__maxHeight {
      main {
        max-height: 300px;
        overflow: scroll;
      }
    }
  }

  .error {
    color: var(--negative);
    font-size: size("font--small");
    position: absolute;
    left: 0;
    top: calc(100% - #{$blockPaddingBottom - 0.45rem});
  }

}

.radioGroupField {
  .radioGroup {
    display: flex;
    flex-direction: column;
    gap: size(gutter--mini);
    margin-bottom: size(gutter--larger);

    legend {
      margin-bottom: size(gutter--mini);
    }
  }

  .error {
    color: var(--negative);
    font-size: size("font--small");
    position: absolute;
    left: 0;
    bottom: calc(0 - #{$blockPaddingBottom - 0.15rem});
  }
}
.radio {
  display: flex;
  align-items: flex-start;
  width: 100%;

  &:hover label {
    text-decoration: none !important;
  }
}