@import "ui-components/_variables.scss";
@import "ui-components/_mixins.scss";

$iconSideLength: 36px;

.banner {
  padding: size("gutter--medium") 0;
  background-color: #fff;
  z-index: 1;
  border-top: 1px solid color("border");
  border-bottom: 1px solid color("border");

  .wrapper[class] {
    padding-top: 0;
  }

  p {
    margin: 0;
  }
}

