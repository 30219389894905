@import "ui-components/_variables.scss";
@import "ui-components/_mixins.scss";

.split {
  display: flex;
  flex-direction: column;

  @media (min-width: breakpoint("small")) {
    flex-direction: row;
    @include stretch(size("gutter"));

    .left {
      margin-right: size("gutter--huge") * 2;
    }

    & > div {
      padding: 0 size("gutter");
      &:first-child { flex: 1 1 auto; }
      &:last-child {
        flex: 1 0 370px;
        @media (max-width: (breakpoint("medium") - 1px)) and
               (min-width: breakpoint("small")) {
          flex: 1 0 340px;
        }
      }
    }
  }
}

.content {
  font-size: size("font--medium-small");
  line-height: font("line-height--medium-small");

  ul, ol {
    list-style-type: disc;
    list-style-position: inside;
    line-height: font("line-height");
  }

  ol {
    list-style-type: decimal;
  }

  li + li {
    margin-top: size("gutter");
  }
}

.items {
  @media (max-width: (breakpoint("small") - 1px)) {
    margin-top: size("gutter--large") * 2.5;
  }

  &Heading {
    font-size: size("font--large");
    font-family: var(--fontBody), var(--fontBodyFallback, #{font("fallback")});
  }

  & > * + * {
    margin-top: size("gutter");
  }
}

.image {
  position: relative;
  max-width: none;
  width: 100vw;
  left: 50%;
  right: 50%;
  margin-left: -50vw;
  margin-right: -50vw;
  margin-top: -#{size("header-margin")};
  margin-bottom: size("gutter--large") * 2;
}
