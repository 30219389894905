@import "ui-components/_variables.scss";

.block {
  background: var(--secondary);
  color: var(--invertedText) !important;
  display: flex;
  align-items: center;
  height: calc(100% + 1px);

  svg {
    width: 35px;
    height: 35px;
  }

  &.active {
    text-decoration: underline;
  }
}

.iconWrapper {
  padding: 0 size("gutter--small");
  background: var(--secondary-d1);
  flex: 0 1 auto;
  height: 100%;
  display: flex;
  align-items: center;
}

.title {
  padding: 0 size("gutter");
  padding-right: size("gutter--large");
}

.count {
  font-size: 1.1em;
  font-weight: bold;
}
