@use "sass:math";

@import "ui-components/_variables.scss";
@import "ui-components/_mixins.scss";

.body {
  padding: size("gutter") size("gutter--large");

  &__top {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}

.subheader {
  color: var(--font);
  font-size: var(--font);
}

.reset_button {
  color: var(--textMuted);
}

.price_slider {
  padding: size("gutter") 8px;

  &__labels {
    display: flex;
    justify-content: space-between;
    color: var(--textMuted);
  }
}

.price_inputs {
  padding-top: size("gutter--large");
  display: flex;
  align-items: center;

  .input {
    background-color: var(--light);
  }

  & > div {
    flex: 1;

    &:first-child {
      padding-right: math.div(size("gutter"), 2);
    }

    &:last-child {
      padding-left: math.div(size("gutter"), 2);
    }
  }
}
