@import "ui-components/_variables.scss";
@import "ui-components/_mixins.scss";

$pagination-btn-size: 2.5rem;

.wrapper {
  background: #fff;

  .intro {
    background: #fff;

    h1 {
      margin-bottom: 0;
    }

    p {
      max-width: 650px;
    }

    .introSection {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: size("gutter");

      article {
        display: flex;
        justify-content: space-between;
        align-items: center;

        p {
          margin: 0;
          margin-right: size("gutter--small");
        }

        @media (max-width: breakpoint("small")) {
          margin: size("gutter") 0;
        }
      }

      @media (max-width: breakpoint("small")) {
        align-items: flex-start;
        flex-direction: column;
      }
    }
  }

  .overview {
    .overviewBar {
      display: flex;
      align-items: center;
      justify-content: space-between;

      @media (max-width: breakpoint("small")) {
        align-items: flex-start;
        flex-direction: column;
        align-items: stretch;
      }

      .userInfo {
        display: flex;

        @media (max-width: breakpoint("small")) {
          flex-direction: column;
        }
      }

      section {
        display: flex;
        align-items: center;

        @media (max-width: breakpoint("small")) {
          margin-top: size("gutter--small");
        }

        .score {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 65px;
          height: 65px;
          background-color: var(--primary);
          border-radius: 50%;
          color: var(--light);
        }

        p {
          margin-top: 0;
          margin: 0 size("gutter--small");
        }

        &:nth-child(3) {
          @media (min-width: breakpoint("small")) {
            margin-left: auto;
          }
          margin-right: 0;
        }
      }
      .columnFilter {
        flex-direction: column;
        align-items: flex-end;
        gap: size("grid-gutter");

        @media (max-width: breakpoint("small")) {
          margin-top: size("gutter--larger");
          align-items: stretch;
        }

        label {
          margin-bottom: size("gutter--mini");
        }
      }

      .dropdown {
        width: 250px;
        border-radius: var(--borderRadius);
        button .head {
          background: #aaa !important;
        }

        @media (max-width: breakpoint("small")) {
          width: 100%;
        }
      }
    }
  }
}

.paginationContainer {
  margin: size("gutter--large") 0;

  .paginationButton {
    display: inline-flex;
    justify-content: center;
    align-content: center;
    background: #eee;
    color: var(--primary);
    line-height: $pagination-btn-size;
    border-radius: var(--borderRadius);
    margin-right: size("gutter--mini");
    border: none;

    height: $pagination-btn-size;
    width: $pagination-btn-size;

    &:hover {
      background: var(--primary);
      color: var(--light);
    }

    &:first-child,
    &:last-child {
      display: none;
    }
  }

  .active {
    background: var(--primary);
    color: var(--light);
  }
}

.leaderboardTableHead {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  width: 100%;
  padding: 0 0 size("gutter--larger");
  margin-top: size("gutter--huge");

  p {
    margin: 0 size("gutter") size("grid-gutter") 0;
  }

  h2 {
    padding: 0;
    margin-bottom: 0;
  }

  .right {
    display: flex;
    flex-direction: row;
    align-items: baseline;
    gap: size("gutter");

    article {
      display: flex;
      align-items: baseline;

      @media (max-width: breakpoint("small")) {
        flex-direction: column;
        align-items: inherit;
      }
    }

    .widgetCtas {
      display: flex;
      align-items: baseline;
      gap: size("grid-gutter");
    }

    @media (max-width: breakpoint("small")) {
      flex-direction: column;
      align-items: inherit;
      gap: size("gutter--medium");
    }
  }

  @media (max-width: breakpoint("small")) {
    flex-direction: column;
    align-items: stretch;
    gap: size("gutter--large");
  }
}
