@import "ui-components/_variables.scss";
@import "ui-components/_mixins.scss";

.btn {
    background: color("text");
    color: #efefef;
    padding: size("grid-gutter");
  }

.goBtn {
  background: color("text");
  padding: size("grid-gutter"); 
  color: #efefef;
  align-self: center;
  border-radius: 50%;
  width: 44px;
  height: 44px;

  display: flex;
  justify-content: center;

  &:hover {
    background-color: var(--primary);
  }

  &:disabled {
    background-color: color("text--muted");
  }
}

.dateInput {
  padding-bottom: 0;
}

.yearDropdown {
  width: 100%;
}

.ymSelectField {
  display: flex;
  gap: size("grid-gutter");
  align-items: baseline;
}

.activeLeaderboard {
  background: var(--primary) !important;
  pointer-events: none;
}

.dropdown {
  &__maxHeight {
    main {
      max-height: 158px;
      overflow-y: scroll;
    }
  }
}