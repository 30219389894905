@import "ui-components/_variables.scss";

.adminButton {
  &:hover {
    text-decoration: none !important;
  }
}

.button {
  @media (max-width: (breakpoint("small") - 1px)) {
    display: flex !important;
    margin-left: auto;
  }

  @media (max-width: (breakpoint("tiny") - 1px)) {
    width: 100%;
  }
}
