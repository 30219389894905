@import "ui-components/_variables.scss";
@import "ui-components/_mixins.scss";

$maxWidth: 350px;
$boxShadow: 0 24px 50px 0 rgba(0, 0, 0, 0.16);
$borderRadius: 6px;
$transitionDuration: transition("duration--organisms");

:export {
  messageTransition: strip-unit($transitionDuration);
}

@keyframes enter {
  0%   {
    opacity: 0;
    transform: translateX(100%);
  }

  50% {
    opacity: 0;
  }

  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes leave {
  0%   {
    opacity: 1;
    height: var(--message-height);
    padding-bottom: #{size("gutter--mini")};
  }

  50% {
    opacity: 0;
  }

  100% {
    opacity: 0;
    height: 0px;
    padding-bottom: 0px;
  }
}

.block {
  position: absolute;
  right: size("gutter--small");
  top: size("gutter--small");
  min-width: 200px;

  backface-visibility: hidden;

  @media (max-width: (breakpoint("tiny") - 1px)) {
    width: 100%;
    left: 0;
    right: 0;
    top: 0;
  }

  pointer-events: none;
  z-index: z-index("overlay") + 2;
}

.list {
  top: 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;

  @media (min-width: breakpoint("tiny")) {
    top: size("gutter");
    right: size("gutter--large");
    align-items: flex-end;
  }
}

.messages {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  margin-left: size("gutter--small");

  @media (max-width: (breakpoint("tiny") - 1px)) {
    margin-left: 0px;
  }

  .message {
    padding-bottom: size("gutter--mini");
    pointer-events: auto;
    max-width: $maxWidth;
    min-height: 0;

    @media (max-width: (breakpoint("tiny") - 1px)) {
      max-width: none;
      width: 100%;
    }

    &.deleting {
      animation: leave $transitionDuration transition("ease-out") forwards;
    }
  }
}

.itemInner {
  animation: enter $transitionDuration transition("ease-in") forwards;
  box-shadow: $boxShadow;
  border-radius: $borderRadius;
  background: var(--light);
  padding: size("gutter--medium");
  color: var(--textMuted);
  cursor: pointer;

  @media (max-width: (breakpoint("tiny") - 1px)) {
    border-radius: 0px;
  }

  h2 {
    margin: 0;
    margin-right: size("gutter--large");
    margin-bottom: size("gutter");

    .success & {
      color: var(--positive);
    }

    .error & {
      color: var(--negative);
    }
  }

  p {
    margin-top: 0;
  }
}

.icon {
  position: absolute;
  color: var(--text);
  width: 20px;
  height: 20px;
  top: 0px;
  right: 0px;
}
