@import "ui-components/_variables.scss";
@import "ui-components/_mixins.scss";

.history {
  margin: 0 0 size("gutter--large");


  &Header {
    @media (min-width: breakpoint("medium")) {
      margin: 0;
    }
  }

  &Table {
    width: 100%;
  }

  tr:not(:last-of-type) {
    border-bottom: 1px solid var(--border);
  }

  th {
    font-size: size("font--small");
    text-align: left;
    font-weight: 400;
    border-bottom: 1px solid var(--border);
    padding: size("gutter--small") size("gutter--small") size("gutter--small") 0;
  }

  td {
    vertical-align: top;
    padding: size("gutter--small") 0;
    line-height: font("line-height--paragraph");

    &:not(:last-child) {
      padding-right: size("gutter--small");
    }
  }
}