@use "sass:math";

@import "ui-components/_variables.scss";
@import "ui-components/_mixins.scss";

.description {
  font-size: size("font--medium");
  line-height: font("line-height--medium");
  margin: 0 0 size("paragraph-margin");

  @media (max-width: (breakpoint("medium") - 1px)) {
    font-size: size("font--medium-small");
    line-height: font("line-height--medium-small");
    margin: 0 0 size("gutter--medium-large") * 2;
  }

  @media (max-width: (breakpoint("tiny") - 1px)) {
    font-size: size("font--small");
    line-height: font("line-height--paragraph");
    margin: 0 0 math.div(size("paragraph-margin"), 2);
  }
}
